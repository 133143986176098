'use client'

import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { useUserAuth } from '../context/userAuthContext';

// type ForgotPasswordFormInputs = {
//   email: string;
// }

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { passwordReset } = useUserAuth();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    console.log(email);
    await passwordReset(email);
    setIsSubmitted(true);
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Forgot your password?
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}>
          You&apos;ll get an email with a reset link
        </Text>
        <FormControl id="email">
          <Input
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
            value={email}
            onChange={handleEmailChange}
            isDisabled={isSubmitted}
          />
        </FormControl>
        <Stack spacing={6}>
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={handleSubmit}
            isDisabled={isSubmitted}>
            {isSubmitted ? 'Submitted' : 'Reset Password'}
          </Button>
        </Stack>
        {isSubmitted && (
          <Text fontSize={{ base: 'sm', sm: 'md' }}>
            Please check your email for the reset link. Reset password 
            and go to the <Link href="/login" color='blue.500'>Login Page</Link>.
          </Text>
        )}
      </Stack>
    </Flex>
  );
}
