import { useEffect } from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import Navbar from "../components/Navbar"
import SignupModal from "../components/SignupModal"
import ReactGA from 'react-ga4';

export const SignupPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/signup", title: "SignupPage" });
  }, []);
  
  return (
  <ChakraProvider theme={theme}>
    <Navbar />
    <SignupModal />
  </ChakraProvider>
)}
