// import { useEffect } from "react"
// import {
//   ChakraProvider,
//   Box,
//   Grid,
//   theme,
//   Card,
//   Button,
//   CardBody,
//   Flex,
//   Image,
//   Stack,
//   Heading,
//   Text,
//   StackDivider
// } from "@chakra-ui/react"
// import Navbar from "./components/Navbar"
// import ReactGA from 'react-ga4';

// export const HomePage = () => {
//   useEffect(() => {
//     ReactGA.send({ hitType: "pageview", page: "/", title: "HomePage" });
//   }, []);
  
//   return (
//   <ChakraProvider theme={theme}>
//     <Navbar />
//     <Box>
//       <Flex
//         minH={{ base: "80vh", md: "85vh" }}
//         align="center"
//         justify="center"
//         ml={{ base: 4, md: 10 }}
//         mr={{ base: 4, md: 10 }}
//         p={4}
//       >
//         <Stack spacing={8} align="center">
//           <Heading as="h1" size="xl" textAlign="center">
//             Welcome to JudgeAI
//           </Heading>
//           {/* <Text fontSize={{ base: "md", md: "lg" }} textAlign="center" mt={-7}>
//             Feeling overwhelmed? Use <Button colorScheme="teal" variant="outline" as={"a"} href="\judgement_demo">JudgeAI demo version</Button> to see how JudgeAI works.
//           </Text> */}
//           <Text fontSize={{ base: "md", md: "lg" }} textAlign="center" mt={-7}>
//             Feeling overwhelmed? Use
//             <Box as="span" display={{ base: "block", md: "inline" }}>
//               <Button colorScheme="teal" variant="outline" as={"a"} href="\judgement_demo" m={{ base: 2, md: 2 }}>
//                 JudgeAI demo version
//               </Button>
//             </Box>
//             to see how JudgeAI works. Or download and see our 
//             <Box as="span" display={{ base: "block", md: "inline" }}>
//               <Button colorScheme="teal" variant="outline" as={"a"} href="\test_cases" m={{ base: 2, md: 2 }}>
//                 Test Cases
//               </Button>
//             </Box>
//           </Text>

//           <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={10}>
//             <Card maxW='xs'>
//               <CardBody>
//                 <Image
//                   src='homepage_1.webp'
//                   alt='automated judicial processing'
//                   borderRadius='lg'
//                 />
//                 <Stack mt='6' spacing='3'>
//                   <Button variant={"outline"} as={'a'} href={"judgement"}>Automated Judicial Processing</Button>
//                   <StackDivider />
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       Description
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                       This feature provides a second
//                       opinion on economic disputes
//                       , which can be useful
//                       for judges, legal practitioners, and
//                       companies involved in litigation.
//                     </Text>
//                   </Box>
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       What it does
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                     JudgeAI automates the judicial process from case registration to final judgment. It processes documents, analyzes evidence, and generates judicial decisions and comprehensive reports.
//                     </Text>
//                   </Box>
//                 </Stack>
//               </CardBody>
//             </Card>
//             <Card maxW='xs'>
//               <CardBody>
//                 <Image
//                   src='homepage_2.webp'
//                   alt='Litigation Perspective Analysis'
//                   borderRadius='lg'
//                 />
//                 <Stack mt='6' spacing='3'>
//                   <Button variant={"outline"} as={'a'} href={"perspective"}>Litigation Perspective Analysis</Button>
//                   <StackDivider />
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       Description
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                     JudgeAI evaluates the submitted
//                     documents to predict the success of
//                     a case, providing strategic
//                     recommendations and suggesting
//                     potential evidence to strengthen the
//                     case.
//                     </Text>
//                   </Box>
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       What it does
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                       It assesses the strength and
//                       relevance of the provided evidence,
//                       determines the likely outcomes of
//                       the case, suggests tactics to improve
//                       a party’s position, and identifies
//                       missing evidence that could be
//                       crucial
//                     </Text>
//                   </Box>
//                 </Stack>
//               </CardBody>
//             </Card>            
//             <Card maxW='xs'>
//               <CardBody>
//                 <Image
//                   src='homepage_3.webp'
//                   alt='Fair Settlement Calculation'
//                   borderRadius='lg'
//                 />
//                 <Stack mt='6' spacing='3'>
//                   <Button variant={"outline"} as={'a'} href={"settlement"}>Fair Settlement Calculation</Button>
//                   <StackDivider />
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       Description
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                       The platform calculates equitable
//                       settlement terms based on a
//                       thorough analysis of both the claim
//                       statement and the defendant’s
//                       position.
//                     </Text>
//                   </Box>
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       What it does
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                       It conducts a detailed analysis of the
//                       parties' positions, calculates a fair
//                       settlement amount, proposes
//                       conditions that can satisfy both parties,
//                       and facilitates reaching an amicable
//                       agreement.
//                     </Text>
//                   </Box>
//                 </Stack>
//               </CardBody>
//             </Card>
//             <Card maxW='xs'>
//               <CardBody>
//                 <Image
//                 src='homepage_4.webp'
//                 alt='codified law judgements'
//                 borderRadius='lg'
//                 />
//                 <Stack mt='6' spacing='3'>
//                   <Button variant={"outline"} as={'a'} href={"codified_law"}>Codified Law Analysis</Button>
//                   <StackDivider />
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       Description
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                     This feature applies the principles
// of the UAE Civil Code to resolve
// disputes, ensuring accurate legal
// adjudication.
//                     </Text>
//                   </Box>
//                   <Box>
//                     <Heading size='xs' textTransform='uppercase'>
//                       What it does
//                     </Heading>
//                     <Text pt='2' fontSize='sm'>
//                     It analyzes the case considering
// the specific norms of the UAE Civil
// Code, applies relevant articles of
// the law to make a decision, and
// ensures compliance with legal
// standards and rules within the
// UAE jurisdiction.
//                     </Text>
//                   </Box>
//                 </Stack>
//               </CardBody>
//             </Card>
//           </Grid>
//         </Stack>
//       </Flex>
//     </Box>
//   </ChakraProvider>
// )
// }



import { useEffect, useState } from "react"
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  Card,
  Button,
  CardBody,
  Flex,
  Image,
  Stack,
  Heading,
  Text,
  StackDivider,
  Skeleton
} from "@chakra-ui/react"
import Navbar from "./components/Navbar"
import ReactGA from 'react-ga4';

export const HomePage = () => {
  const [isImageLoaded, setImageLoaded] = useState({
    homepage_1: false,
    homepage_2: false,
    homepage_3: false,
    homepage_4: false
  });

  const handleImageLoad = (imageName: keyof typeof isImageLoaded) => {
    setImageLoaded(prevState => ({ ...prevState, [imageName]: true }));
  };
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "HomePage" });
  }, []);
  
  return (
  <ChakraProvider theme={theme}>
    <Navbar />
    <Box>
      <Flex
        minH={{ base: "80vh", md: "85vh" }}
        align="center"
        justify="center"
        ml={{ base: 4, md: 10 }}
        mr={{ base: 4, md: 10 }}
        p={4}
      >
        <Stack spacing={8} align="center">
          <Heading as="h1" size="xl" textAlign="center">
            Welcome to JudgeAI
          </Heading>
          <Text fontSize={{ base: "md", md: "lg" }} textAlign="center" mt={-7}>
            Feeling overwhelmed? Use
            <Box as="span" display={{ base: "block", md: "inline" }}>
              <Button colorScheme="teal" variant="outline" as={"a"} href="\judgement_demo" m={{ base: 2, md: 2 }}>
                JudgeAI demo version
              </Button>
            </Box>
            to see how JudgeAI works. Or download and see our 
            <Box as="span" display={{ base: "block", md: "inline" }}>
              <Button colorScheme="teal" variant="outline" as={"a"} href="\test_cases" m={{ base: 2, md: 2 }}>
                Test Cases
              </Button>
            </Box>
          </Text>

          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={10}>
            <Card maxW='xs'>
              <CardBody>
                <Skeleton isLoaded={isImageLoaded.homepage_1}>
                  <Image
                    src='homepage_1.webp'
                    alt='automated judicial processing'
                    borderRadius='lg'
                    onLoad={() => handleImageLoad('homepage_1')}
                  />
                </Skeleton>
                <Stack mt='6' spacing='3'>
                  <Button variant={"outline"} as={'a'} href={"judgement"}>Automated Judicial Processing</Button>
                  <StackDivider />
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Description
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      This feature provides a second
                      opinion on economic disputes
                      , which can be useful
                      for judges, legal practitioners, and
                      companies involved in litigation.
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      What it does
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                    JudgeAI automates the judicial process from case registration to final judgment. It processes documents, analyzes evidence, and generates judicial decisions and comprehensive reports.
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW='xs'>
              <CardBody>
                <Skeleton isLoaded={isImageLoaded.homepage_2}>
                  <Image
                    src='homepage_2.webp'
                    alt='Litigation Perspective Analysis'
                    borderRadius='lg'
                    onLoad={() => handleImageLoad('homepage_2')}
                  />
                </Skeleton>
                <Stack mt='6' spacing='3'>
                  <Button variant={"outline"} as={'a'} href={"perspective"}>Litigation Perspective Analysis</Button>
                  <StackDivider />
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Description
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                    JudgeAI evaluates the submitted
                    documents to predict the success of
                    a case, providing strategic
                    recommendations and suggesting
                    potential evidence to strengthen the
                    case.
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      What it does
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      It assesses the strength and
                      relevance of the provided evidence,
                      determines the likely outcomes of
                      the case, suggests tactics to improve
                      a party’s position, and identifies
                      missing evidence that could be
                      crucial
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>            
            <Card maxW='xs'>
              <CardBody>
                <Skeleton isLoaded={isImageLoaded.homepage_3}>
                  <Image
                    src='homepage_3.webp'
                    alt='Fair Settlement Calculation'
                    borderRadius='lg'
                    onLoad={() => handleImageLoad('homepage_3')}
                  />
                </Skeleton>
                <Stack mt='6' spacing='3'>
                  <Button variant={"outline"} as={'a'} href={"settlement"}>Fair Settlement Calculation</Button>
                  <StackDivider />
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Description
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      The platform calculates equitable
                      settlement terms based on a
                      thorough analysis of both the claim
                      statement and the defendant’s
                      position.
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      What it does
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      It conducts a detailed analysis of the
                      parties' positions, calculates a fair
                      settlement amount, proposes
                      conditions that can satisfy both parties,
                      and facilitates reaching an amicable
                      agreement.
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW='xs'>
              <CardBody>
                <Skeleton isLoaded={isImageLoaded.homepage_4}>
                  <Image
                  src='homepage_4.webp'
                  alt='codified law judgements'
                  borderRadius='lg'
                  onLoad={() => handleImageLoad('homepage_4')}
                  />
                </Skeleton>
                <Stack mt='6' spacing='3'>
                  <Button variant={"outline"} as={'a'} href={"codified_law"}>Codified Law Analysis</Button>
                  <StackDivider />
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Description
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                    This feature applies the principles
of the UAE Civil Code to resolve
disputes, ensuring accurate legal
adjudication.
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      What it does
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                    It analyzes the case considering
the specific norms of the UAE Civil
Code, applies relevant articles of
the law to make a decision, and
ensures compliance with legal
standards and rules within the
UAE jurisdiction.
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </Grid>
        </Stack>
      </Flex>
    </Box>
  </ChakraProvider>
)
}
