import { useEffect } from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import Navbar from "../components/Navbar"
import ForgotPassword from "../components/ForgotPassword";
import ReactGA from 'react-ga4';

export const ForgotPasswordPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/reset_password", title: "ForgotPasswordPage" });
  }, []);

  return (
  <ChakraProvider theme={theme}>
    <Navbar />
    <ForgotPassword />
  </ChakraProvider>
)}
