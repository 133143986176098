import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/userAuthContext';
import { FcGoogle } from "react-icons/fc";


export default function LoginModal() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { logIn, googleSimpleSignIn } = useUserAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log({
      email,
      password,
    });

    try {
      const userCredential = await logIn(email, password);
      const user = userCredential.user;
      navigate('/');
      console.log('User signed in:', user);
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Error signing in. Please check your email and password.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const userCredential = await googleSimpleSignIn();
      navigate('/');
      console.log('User signed in:', userCredential.user);
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('User does not exist. Please sign up.');
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox>Remember me</Checkbox>
                  <Link color={'blue.400'} href='/reset_password'>Forgot password?</Link>
                </Stack>
                {error && (
                  <Box color="red.500" fontSize="sm">
                    {error}
                  </Box>
                )}
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  type='submit'>
                  Sign in
                </Button>
              </Stack>
              <Button
                leftIcon={<FcGoogle />}
                loadingText="Submitting"
                bg={'white'}
                color={'black'}
                borderWidth="1px"
                // borderRadius="lg"
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={handleGoogleSignIn}>
                Continue with Google
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}
