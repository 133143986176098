// import { useEffect } from "react"
// import {
//   ChakraProvider,
//   theme,
//   Heading,
//   Box,
//   Text,
//   Grid,
//   Stack,
//   Flex,
//   IconButton,
//   Divider,
// } from "@chakra-ui/react"
// import Navbar from "../components/Navbar"
// import { GrDocumentText } from "react-icons/gr";
// import ReactGA from 'react-ga4';

// export const TestCasesPage = () => {
//   useEffect(() => {
//     ReactGA.send({ hitType: "pageview", page: "/test_cases", title: "TestCasesPage" });
//   }, []);

//   return (
//   <ChakraProvider theme={theme}>
//     <Navbar />
//     <Box p={4}>
//       <Heading as="h1" size="4xl" color="teal" textAlign="center" mb={{ base: 20, md: 20 }} mt={10}>
//         Test Cases
//       </Heading>

//       <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
//         <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }} 
//         // border={"2px solid red"}
//         >
//           <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
//             Loan Repayment Dispute
//           </Heading>
//           <Text mb={4}>
//             The claimant, Emily Robinson, demands repayment of a $20,000 loan
//             from Michael Johnson, based on a loan agreement dated January 15,
//             2023, which was due for repayment by January 15, 2024. Johnson did
//             not repay the loan on time, which constitutes a breach of contract.
//             Robinson also seeks accrued interest and reimbursement of legal
//             costs.
//           </Text>
//           <Text mb={4}>
//             Johnson acknowledges the loan but attributes the delay to financial
//             difficulties caused by losing his job during the COVID-19 pandemic.
//             He attempted to negotiate new repayment terms, but no agreement was
//             reached. Johnson asks the court to consider his circumstances and
//             dismiss the claim for legal costs.
//           </Text>
//           <Divider borderColor={"teal"} borderWidth={"thin"}/>
//           <Flex justify="space-around" mt={8}>
//             <Stack align="center">
//               <Heading as="h4" size="md">
//                 Claimant
//               </Heading>
//               <IconButton
//                     icon={<GrDocumentText size={"70px"} />}
//                     aria-label="Remove file"
//                     size="lg"
//                     boxSize={"100px"}
//                 />
//             </Stack>
//             <Divider orientation="vertical" height="150px" borderColor={"teal"} borderWidth={"thin"}/>
//             <Stack align="center">
//               <Heading as="h4" size="md">
//                 Defendant
//               </Heading>
//               <IconButton
//                     icon={<GrDocumentText size={"70px"} />}
//                     aria-label="Remove file"
//                     size="lg"
//                     boxSize={"100px"}
//                 />
//             </Stack>
//           </Flex>
//         </Box>

//         <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }}>
//           <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
//             Vehicle Purchase Dispute
//           </Heading>
//           <Text mb={4}>
//             On January 1, 2024, Ivanov Ivan Ivanovich and Johnson James entered
//             into a vehicle purchase agreement for a BMW X5, 2022 model, with a
//             purchase price of 250,000 AED. The payment was to be made in two
//             installments: 150,000 AED upon signing the agreement and 100,000 AED
//             by March 1, 2024. Ivanov delivered the vehicle, and Johnson made the
//             first payment but failed to pay the second installment. Ivanov seeks
//             the outstanding amount, interest, and legal costs.
//           </Text>
//           <Text mb={4}>
//             Johnson acknowledges the agreement and payment but claims the
//             vehicle had multiple defects, making it unfit for its intended use.
//             Johnson argues he notified Ivanov of these defects and demands the
//             dismissal of the claim, replacement or repair of the vehicle,
//             compensation for damages, and legal costs.
//           </Text>
//           <Divider borderColor={"teal"} borderWidth={"thin"}/>
//           <Flex justify="space-around" mt={8}>
//             <Stack align="center">
//               <Heading as="h4" size="md">
//                 Claimant
//               </Heading>
//               <IconButton
//                     icon={<GrDocumentText size={"70px"} />}
//                     aria-label="Remove file"
//                     size="lg"
//                     boxSize={"100px"}
//                 />
//             </Stack>
//             <Divider orientation="vertical" height="150px" borderColor={"teal"} borderWidth={"thin"}/>
//             <Stack align="center">
//               <Heading as="h4" size="md">
//                 Defendant
//               </Heading>
//               <IconButton
//                     icon={<GrDocumentText size={"70px"} />}
//                     aria-label="Remove file"
//                     size="lg"
//                     boxSize={"100px"}
//                 />
//             </Stack>
//           </Flex>
//         </Box>
//       </Grid>
//     </Box>
//   </ChakraProvider>
// )}


import { useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Heading,
  Box,
  Text,
  Grid,
  Stack,
  Flex,
  IconButton,
  Divider,
  Link,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import { GrDocumentText } from "react-icons/gr";
import ReactGA from 'react-ga4';

export const TestCasesPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/test_cases", title: "TestCasesPage" });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box p={4}>
        <Heading as="h1" size="4xl" color="teal" textAlign="center" mb={{ base: 20, md: 20 }} mt={10}>
          Test Cases
        </Heading>

        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
          <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }} 
          // border={"2px solid red"}
          >
            <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
              Loan Repayment Dispute
            </Heading>
            <Text mb={4}>
              The claimant, Emily Robinson, demands repayment of a $20,000 loan
              from Michael Johnson, based on a loan agreement dated January 15,
              2023, which was due for repayment by January 15, 2024. Johnson did
              not repay the loan on time, which constitutes a breach of contract.
              Robinson also seeks accrued interest and reimbursement of legal
              costs.
            </Text>
            <Text mb={4}>
              Johnson acknowledges the loan but attributes the delay to financial
              difficulties caused by losing his job during the COVID-19 pandemic.
              He attempted to negotiate new repayment terms, but no agreement was
              reached. Johnson asks the court to consider his circumstances and
              dismiss the claim for legal costs.
            </Text>
            <Divider borderColor={"teal"} borderWidth={"thin"}/>
            <Flex justify="space-around" mt={8}>
              <Stack align="center">
                <Heading as="h4" size="md">
                  Claimant
                </Heading>
                <Link href="/testcases/Lawsuit_Loan.pdf" download>
                  <IconButton
                      icon={<GrDocumentText size={"70px"} />}
                      aria-label="Download claimant file"
                      size="lg"
                      boxSize={"100px"}
                  />
                </Link>
              </Stack>
              <Divider orientation="vertical" height="150px" borderColor={"teal"} borderWidth={"thin"}/>
              <Stack align="center">
                <Heading as="h4" size="md">
                  Defendant
                </Heading>
                <Link href="/testcases/Response_Loan.pdf" download>
                  <IconButton
                      icon={<GrDocumentText size={"70px"} />}
                      aria-label="Download defendant file"
                      size="lg"
                      boxSize={"100px"}
                  />
                </Link>
              </Stack>
            </Flex>
          </Box>

          <Box ml={{ base: 2, md: 20 }} mr={{ base: 2, md: 20 }}>
            <Heading as="h3" size="lg" mb={8} textAlign={"center"} color={"blue"}>
              Vehicle Purchase Dispute
            </Heading>
            <Text mb={4}>
              On January 1, 2024, Ivanov Ivan Ivanovich and Johnson James entered
              into a vehicle purchase agreement for a BMW X5, 2022 model, with a
              purchase price of 250,000 AED. The payment was to be made in two
              installments: 150,000 AED upon signing the agreement and 100,000 AED
              by March 1, 2024. Ivanov delivered the vehicle, and Johnson made the
              first payment but failed to pay the second installment. Ivanov seeks
              the outstanding amount, interest, and legal costs.
            </Text>
            <Text mb={4}>
              Johnson acknowledges the agreement and payment but claims the
              vehicle had multiple defects, making it unfit for its intended use.
              Johnson argues he notified Ivanov of these defects and demands the
              dismissal of the claim, replacement or repair of the vehicle,
              compensation for damages, and legal costs.
            </Text>
            <Divider borderColor={"teal"} borderWidth={"thin"}/>
            <Flex justify="space-around" mt={8}>
              <Stack align="center">
                <Heading as="h4" size="md">
                  Claimant
                </Heading>
                <Link href="/testcases/Lawsuit_vehicle.pdf" download>
                  <IconButton
                      icon={<GrDocumentText size={"70px"} />}
                      aria-label="Download claimant file"
                      size="lg"
                      boxSize={"100px"}
                  />
                </Link>
              </Stack>
              <Divider orientation="vertical" height="150px" borderColor={"teal"} borderWidth={"thin"}/>
              <Stack align="center">
                <Heading as="h4" size="md">
                  Defendant
                </Heading>
                <Link href="/testcases/Response_vehicle.pdf" download>
                  <IconButton
                      icon={<GrDocumentText size={"70px"} />}
                      aria-label="Download defendant file"
                      size="lg"
                      boxSize={"100px"}
                  />
                </Link>
              </Stack>
            </Flex>
          </Box>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default TestCasesPage;
