import { useEffect } from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import Navbar from "../components/Navbar"
import LoginModal from "../components/LoginModal"
import ReactGA from 'react-ga4';

export const LoginPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/login", title: "LoginPage" });
  }, []);

  return (
  <ChakraProvider theme={theme}>
    <Navbar />
    <LoginModal />
  </ChakraProvider>
)}
