import {
  Avatar,
  Box,
  Heading,
  VStack,
  Text,
  Stack,
  Badge,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useUserAuth } from '../context/userAuthContext';
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";

  // const auth = getAuth(app);
  
  export default function Profile() {
    const { user } = useUserAuth();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    
    useEffect(() => {
      const fetchUserData = async () => {
        if (user) {
          const userEmail = user.email || '';
          setEmail(userEmail);
          
          try {
            const userDoc = await getDoc(doc(db, "users", userEmail));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setFirstName(userData.firstName || '');
              setLastName(userData.lastName || '');
              console.log("User data:", userData);
            } else {
              console.log("No such document!");
            }
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        }
      };
  
      fetchUserData();
    }, [user]);
    
    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        // border="2px solid red"
        height={"40vh"}
        >
          <VStack>
          <Avatar
          size="2xl"
          name={firstName + " " + lastName}
          // src="https://api.dicebear.com/8.x/fun-emoji/svg?seed=Garfield"
          src="https://bit.ly/broken-link"
          mb={4}
        />
        <Heading fontSize="2xl" fontFamily="body">
          {firstName} {lastName}
        </Heading>
        <Text fontWeight={600} color="gray.500" mb={4}>
          {email}
        </Text>
        <Stack align="center" justify="center" direction="row" mt={0}>
          <Badge px={2} py={1} fontWeight="400">
            User
          </Badge>
          <Badge px={2} py={1} colorScheme='green' fontWeight="400">
            Active
          </Badge>
        </Stack>
        </VStack>
        </Box>
    );
  }