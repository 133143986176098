import { useState, useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import UploadButton from "../components/UploadButton";
import axios from 'axios';
import Markdown from "react-markdown";
import {v4 as uuidv4} from 'uuid';
import { useUserAuth } from "../context/userAuthContext";
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ReactGA from 'react-ga4';

export const SettlementPage = () => {
  const { user } = useUserAuth();

  const [stage0_8Files, setStage0_8Files] = useState<File[]>([]);
  const [stage0_8Output, setStage0_8Output] = useState<string>("");
  const [stage0_8SubmitLoading, setStage0_8SubmitLoading] = useState(false)
  const [stage0_8SubmitDisabled, setStage0_8SubmitDisabled] = useState(false)
  const [stage0_8FileUploading, setStage0_8FileUploading] = useState(false)

  const [showStage0_9, setShowStage0_9] = useState(false)
  const [stage0_9Files, setStage0_9Files] = useState<File[]>([]);
  const [stage0_9SubmitLoading, setStage0_9SubmitLoading] = useState(false)
  const [stage0_9SubmitDisabled, setStage0_9SubmitDisabled] = useState(false)
  const [stage0_9FileUploading, setStage0_9FileUploading] = useState(false)

  const [stage1Output, setStage1Output] = useState<string>("");

  const [stage2Output, setStage2Output] = useState<string>("");
  
  const [stage3Output, setStage3Output] = useState<string>("");

  const [userCredits, setUserCredits] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true);

  const toast = useToast()

  function addToast() {
    // toast({ description: 'some text' })
    toast({
      title: 'JudgeAI is analysing facts',
      description: "JudgeAI typically takes a minute to respond",
      status: 'loading',
      duration: null,
      // isClosable: true,
    })
  }

  function closeAll() {
    toast.closeAll()
  }

  // useEffect for Google Analytics pageview tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/settlement", title: "SettlementPage" });
  }, []); // Empty dependency array means this runs only on mount

  useEffect(() => {
    const fetchUserData = async () => {
        if (user) { 
        const userEmail = user.email || '';
        setUserEmail(userEmail);
          
        try {
            const userDoc = await getDoc(doc(db, "users", userEmail));
            if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserCredits(userData.credits || 0);
            setLoadingScreen(false)
            } else {
            console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
        }
    };

    fetchUserData();
    }, [user]);


    const scrollToSection = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    useEffect(() => {
      if (stage1Output) {
        scrollToSection("bottom");
      }
    }, [stage1Output]);
    
    useEffect(() => {
      if (stage2Output) {
        scrollToSection("bottom");
      }
    }, [stage2Output]);
    
    useEffect(() => {
      if (stage3Output) {
        scrollToSection("bottom");
      }
    }, [stage3Output]);

  const uploadFilesToFirebase = async (files: File[], stage: number) => {
    const uploadedFiles: { fileName: string; url: string }[] = [];

    for (const file of files) {
      const fileRef = ref(storage, `settlement_${userEmail}_${stage}_${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      uploadedFiles.push({ fileName: file.name, url });
    }

    return uploadedFiles;
  };

  // const handleStage0_8FilesChange = async (files: File[]) => {
  //   const uploadedFiles = await uploadFilesToFirebase(files, 1)
  //   setStage0_8Files(files);
  //   console.log(uploadedFiles)
  // };

  // const handleStage0_9FilesChange = async (files: File[]) => {
  //   const uploadedFiles = await uploadFilesToFirebase(files, 2)
  //   setStage0_9Files(files);
  //   console.log(uploadedFiles)
  // };  

  const handleStage0_8FilesChange = async (files: File[]) => {
    setStage0_8SubmitDisabled(true);
    setStage0_8SubmitLoading(true);
    setStage0_8FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 1);
      setStage0_8Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage0_8SubmitDisabled(false);
      setStage0_8SubmitLoading(false);
      setStage0_8FileUploading(false);
    }
  };
  
  const handleStage0_9FilesChange = async (files: File[]) => {
    setStage0_9SubmitDisabled(true);
    setStage0_9SubmitLoading(true);
    setStage0_9FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 2);
      setStage0_9Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage0_9SubmitDisabled(false);
      setStage0_9SubmitLoading(false);
      setStage0_9FileUploading(false);
    }
  };

  const handleSubmitStage0_8 = async () => {
    addToast()
    setStage0_8SubmitLoading(true)

    const userRef = doc(db, "users", userEmail);

    setUserCredits(userCredits - 1)

    await updateDoc(userRef, {
      credits: (userCredits - 1)
    });

    const interaction_id = uuidv4()
    console.log(interaction_id)

    if (stage0_8Files.length > 0) {
      const formData = new FormData();
      stage0_8Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });

      try {
        // const response = await axios.post('http://127.0.0.1:8000/judgement_stage_1/', formData, {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/settlement_stage_0_8/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setStage0_8Output(response.data.response);
        setStage0_8SubmitLoading(false)
        setShowStage0_9(true)
        setStage0_8SubmitDisabled(true);
      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    closeAll()
  };


  const handleSubmitStage0_9 = async () => {
    addToast()
    setStage0_9SubmitLoading(true)

    if (stage0_9Files.length > 0) {
      const formData = new FormData();
      stage0_9Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });

      try {
        // const response = await axios.post('http://127.0.0.1:8000/judgement_stage_1/', formData, {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/settlement_stage_0_9/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setShowStage0_9(true)

        const stage1Input = stage0_8Output + response.data.response;
        const response1 = await axios.post('https://judgeaiv0.azurewebsites.net/settlement_stage_1/', stage1Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage1Output = response1.data.response;
        setStage1Output(stage1Output);

        const stage2Input = stage0_8Output + response.data.response + response1.data.response;
        const response2 = await axios.post('https://judgeaiv0.azurewebsites.net/settlement_stage_2/', stage2Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage2Output = response2.data.response;
        setStage2Output(stage2Output);

        const stage3Input = stage0_8Output + response.data.response + response1.data.response + response2.data.response;
        const response3 = await axios.post('https://judgeaiv0.azurewebsites.net/settlement_stage_3/', stage3Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage3Output = response3.data.response;
        setStage3Output(stage3Output);

        setStage0_9SubmitLoading(false)
        setStage0_9SubmitDisabled(true);

      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    closeAll()
  };

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box paddingTop={"40px"} paddingBottom={"40px"}>
        <VStack spacing={"40px"}>
        {(userCredits === 0) && (!loadingScreen) && (<Box>
          {/* <Alert status='error'>
            <AlertIcon />
            Please purchase credits to use JudgeAI.
          </Alert> */}
          <Alert
          status='error'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
          borderRadius={"10px"}
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Out of Credits!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Please purchase credits to use JudgeAI.
          </AlertDescription>
        </Alert>
        </Box>)}
        {(userCredits > 0) && (<Box>
          <VStack spacing={"10px"}>
          <UploadButton onFilesChange={handleStage0_8FilesChange} isDisabled={(stage0_8SubmitDisabled || stage0_8SubmitLoading)} uploading={stage0_8FileUploading} />
          <Button colorScheme="teal" onClick={handleSubmitStage0_8} isDisabled={stage0_8SubmitDisabled} isLoading={stage0_8SubmitLoading}>
            Submit
          </Button>
          </VStack>
        </Box>)}
          {showStage0_9 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <UploadButton onFilesChange={handleStage0_9FilesChange} 
                              message="Upload response and evidence from defendant" 
                              isDisabled = {stage0_9SubmitDisabled || stage0_9SubmitLoading}
                              uploading = {stage0_9FileUploading}
                              />
                <Button colorScheme="teal" onClick={handleSubmitStage0_9} isDisabled={stage0_9SubmitDisabled} isLoading={stage0_9SubmitLoading}>
                  Submit
                </Button>
              </VStack>
            </Box>
          )}
          {stage1Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Case Fable</Heading>
              <Markdown>{stage1Output}</Markdown>
            </Box>
            </>
          )}
          {stage2Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Chronology</Heading>
              <Markdown>{stage2Output}</Markdown>
            </Box>
            </>
          )}
          {stage3Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Settlement Terms</Heading>
              <Markdown>{stage3Output}</Markdown>
            </Box>
            </>
          )}
        </VStack>
        <div id="bottom">
        </div>
      </Box>
    </ChakraProvider>
  );
};

export default SettlementPage;