// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLLU7FPmSTC2gtbWX7KRjxgkFqsYyK2vQ",
  authDomain: "judgeai-backend-v0.firebaseapp.com",
  projectId: "judgeai-backend-v0",
  storageBucket: "judgeai-backend-v0.appspot.com",
  messagingSenderId: "15238985745",
  appId: "1:15238985745:web:fcf6efa43f64da898f19b0",
  measurementId: "G-ELTE0K1GGH"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);