import { useEffect } from "react";
import {
    ChakraProvider,
    Box,
    theme,
    Card,
    Button,
    CardBody,
    Flex,
    Image,
    Stack,
    Heading,
    HStack,
  } from "@chakra-ui/react"
  import Navbar from "../components/Navbar"
  import ReactGA from 'react-ga4'
  
  export const CodifiedOptionsPage = () => {
      // useEffect for Google Analytics pageview tracking
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/codified_law", title: "CodifiedOptionsPage" });
    }, []); // Empty dependency array means this runs only on mount

    return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Flex
        align="center"
        justify="center"
        ml={10}
        mr={10}
        mt={10}
      >
        <Heading size="2xl">Codified Law Jurisdictions</Heading>
      </Flex>
      <Box p={10}>
        <HStack gap={50}>
          <Card size="sm" minW="20vw">
            <CardBody>
              <Image src="flags/ae.svg" alt="UAE Flag" />
              <Stack mt="6" spacing="3">
                <Button variant="outline" as="a" href="uae_law">
                  United Arab Emirates
                </Button>
              </Stack>
            </CardBody>
          </Card>
          {/* <Card size="sm" minW="20vw">
            <CardBody position="relative">
              <Box position="relative">
                <Image src="flags/de.svg" alt="German Flag" />
                <Box
                  position="absolute"
                  bottom="10px"
                  right="10px"
                  bg="gray.700"
                  color="white"
                  px="2"
                  py="1"
                  borderRadius="md"
                >
                  <Text fontSize="sm">Coming Soon</Text>
                </Box>
              </Box>
              <Stack mt="6" spacing="3">
                <Button variant="outline" isDisabled>
                  Federal Republic of Germany
                </Button>
              </Stack>
            </CardBody>
          </Card> */}
        </HStack>
      </Box>
    </ChakraProvider>
  )}
