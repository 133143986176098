import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  User,
  UserCredential,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

interface UserAuthContextProps {
  user: User | null;
  logIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  logOut: () => Promise<void>;
  googleSignIn: () => Promise<UserCredential>;
  googleSimpleSignIn: () => Promise<UserCredential>;
  passwordReset: (email: string) => Promise<void>;
}

const userAuthContext = createContext<UserAuthContextProps | undefined>(undefined);

interface UserAuthContextProviderProps {
  children: ReactNode;
}

export function UserAuthContextProvider({ children }: UserAuthContextProviderProps) {
  const [user, setUser] = useState<User | null>(null);

  function logIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  
  function signUp(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  
  function logOut() {
    return signOut(auth);
  }

  function passwordReset(email: string) {
    return sendPasswordResetEmail(auth, email);
  }
  
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  async function googleSimpleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;
      const userRef = doc(db, 'users', user.email || "");
      const userSnap = await getDoc(userRef);
  
      if (userSnap.exists()) {
        return result;
      } else {
        await auth.signOut(); // Sign out the user if they don't exist in Firestore
        throw new Error('User does not exist. Please sign up.');
      }
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
      throw error;  // Re-throw the error to handle it in the component
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth", currentUser);
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, googleSignIn, googleSimpleSignIn, passwordReset }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  const context = useContext(userAuthContext);
  if (context === undefined) {
    throw new Error("useUserAuth must be used within a UserAuthContextProvider");
  }
  return context;
}