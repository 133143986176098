import {
Box,
Heading,
Text,
Stack,
useColorModeValue,
Stat,
StatLabel,
StatNumber,
FormControl,
Button,
Input,
FormErrorMessage,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useUserAuth } from '../context/userAuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";

export default function Billing() {
    const { user } = useUserAuth();

    const [email, setEmail] = useState('');
    const [userCredits, setUserCredits] = useState(0);
    const [enteredCouponCode, setEnteredCouponCode] = useState("")
    const [invalidCoupon, setInvalidCoupon] = useState(false)
    const [userCouponsRedeemed, setUserCouponsRedeemed] = useState<string[]>([]);
    const [redeemLoading, setRedeemLoading] = useState(false)

    
    useEffect(() => {
    const fetchUserData = async () => {
        if (user) {
        const userEmail = user.email || '';
        setEmail(userEmail);
        
        try {
            const userDoc = await getDoc(doc(db, "users", userEmail));
            if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserCredits(userData.credits || 0);
            setUserCouponsRedeemed(userData.coupon_redeemed || [])
            console.log("User data:", userData);
            } else {
            console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
        }
    };

    fetchUserData();
    }, [user]);


    const handleRedeem = async () => {
        setRedeemLoading(true)
        setInvalidCoupon(false)
        // Implement coupon redemption logic here
        console.log("Redeeming coupon with code: ", enteredCouponCode);
        const couponDoc = await getDoc(doc(db, "coupons", enteredCouponCode));
        if (couponDoc.exists()) {
            if (userCouponsRedeemed.includes(enteredCouponCode)) {
                setInvalidCoupon(true)
            }
            else {
                console.log("in")
                setUserCouponsRedeemed([...userCouponsRedeemed, enteredCouponCode])
                console.log(userCouponsRedeemed)
                const userRef = doc(db, "users", email);
                // await updateDoc(userRef, {
                //     coupon_redeemed: userCouponsRedeemed
                // });
                await updateDoc(userRef, {
                    coupon_redeemed: arrayUnion(enteredCouponCode)
                });
                setUserCredits(userCredits + couponDoc.data().credit_reward)
                const const_userCredits = userCredits + couponDoc.data().credit_reward
                await updateDoc(userRef, {
                    credits: const_userCredits
                  });
            }
            console.log(couponDoc.data().credit_reward)
            // setUserCredits(userCredits + couponDoc.data().credit_reward) 
        }
        else {
            console.log("no coupon")
            setInvalidCoupon(true)
            console.log(invalidCoupon)
        }
        setRedeemLoading(false)
    };
    
    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        // border="2px solid red"
        height={"40vh"}
        >
        <Stack>
        <Stat>
        <StatLabel fontSize={"l"}>Credit Balance:</StatLabel>
        <StatNumber fontSize={"4xl"}>{userCredits} Credits</StatNumber>
        </Stat>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }} mt={5}>
        Redeem Coupon
        </Heading>
        <Text
        fontSize={{ base: 'sm', sm: 'md' }}
        color={useColorModeValue('gray.800', 'gray.400')}
        mt={2}
        >
        Enter value of coupon below to redeem your credits.
        </Text>
        <FormControl isInvalid={invalidCoupon}>
        <Input
            placeholder="your seven digit coupon code"
            _placeholder={{ color: 'gray.500' }}
            type="email"
            value={enteredCouponCode}
            onChange={(e) => setEnteredCouponCode(e.target.value)} 
        />
        {invalidCoupon && (
            <FormErrorMessage>
                Invalid coupon code. Please try again.
            </FormErrorMessage>
        )}
        </FormControl>
        <Stack spacing={6}>
        <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
            bg: 'blue.500',
            }}
            isLoading={redeemLoading}
            onClick={handleRedeem}
            >
            Redeem Coupon
        </Button>
        </Stack>
        </Stack>
        </Box>
    );
}