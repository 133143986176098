// import React from 'react';
// import { useDropzone } from 'react-dropzone';
// import {
//   Card,
//   Text,
//   List,
//   ListItem,
//   Box,
//   // CircularProgress,
//   Spinner,
// } from '@chakra-ui/react';
// import { FaCloudUploadAlt } from 'react-icons/fa';

// interface UploadButtonProps {
//   onFilesChange: (files: File[]) => void;
//   message?: string; // Optional prop for custom message
//   isDisabled?: boolean; // Optional prop to disable the dropzone
//   uploading?: boolean; // Optional prop to show loading indicator
// }

// const UploadButton: React.FC<UploadButtonProps> = ({ onFilesChange, message, isDisabled, uploading }) => {
//   const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop: (acceptedFiles) => onFilesChange(acceptedFiles),
//     disabled: isDisabled, // Use the isDisabled prop to disable the dropzone
//   });

//   const files = acceptedFiles.map((file: File) => (
//     <ListItem key={file.name}>
//       {file.name} - {file.size} bytes
//     </ListItem>
//   ));

//   return (
//     <Box>
//       <Card alignItems={"center"}>
//         <section className="container">
//           <div
//             {...getRootProps({ className: 'dropzone' })}
//             style={{ 
//               width: '70vw',
//               display: 'flex',
//               flexDirection: 'column', 
//               justifyContent: 'center', 
//               alignItems: 'center', 
//               border: '2px dashed #cccccc', 
//               borderRadius: '5px',
//               padding: '20px', 
//               textAlign: 'center',
//               opacity: isDisabled ? 0.5 : 1, // Change opacity if disabled
//               pointerEvents: isDisabled ? 'none' : 'auto' // Disable pointer events if disabled
//             }}
//           >
//             <input {...getInputProps()} />
//             {isDragActive ? (
//               <>
//                 <FaCloudUploadAlt size={100} />
//                 <Text fontSize='2xl'>Drop the files here...</Text>
//               </>
//             ) : acceptedFiles.length === 0 ? (
//               <>
//                 <FaCloudUploadAlt size={100} />
//                 <Text fontSize='2xl'>{message || 'Upload Claims and Evidence'}</Text>
//               </>
//             ) : (
//               <>
//                 <List spacing={3}>
//                   {files}
//                 </List>
//                 {uploading && (
//                   <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
//                     <Spinner size="sm" />
//                     <Text fontSize="lg" mt={2}>Uploading...</Text>
//                   </Box>
//                 )}
//               </>
//             )}
//           </div>
//         </section>
//       </Card>
//     </Box>
//   );
// };

// export default UploadButton;

// import React from 'react';
// import { useDropzone } from 'react-dropzone';
// import {
//   Card,
//   Text,
//   List,
//   ListItem,
//   Box,
//   Spinner,
// } from '@chakra-ui/react';
// import { FaCloudUploadAlt } from 'react-icons/fa';

// interface UploadButtonProps {
//   onFilesChange: (files: File[]) => void;
//   message?: string; // Optional prop for custom message
//   isDisabled?: boolean; // Optional prop to disable the dropzone
//   uploading?: boolean; // Optional prop to show loading indicator
// }

// const UploadButton: React.FC<UploadButtonProps> = ({ onFilesChange, message, isDisabled, uploading }) => {
//   const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop: (acceptedFiles) => onFilesChange(acceptedFiles),
//     disabled: isDisabled, // Use the isDisabled prop to disable the dropzone
//     accept: {
//       'application/pdf': [],
//       'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
//     }, // Accept only PDF and DOCX files
//   });

//   const files = acceptedFiles.map((file: File) => (
//     <ListItem key={file.name}>
//       {file.name} - {file.size} bytes
//     </ListItem>
//   ));

//   return (
//     <Box>
//       <Card alignItems={"center"}>
//         <section className="container">
//           <div
//             {...getRootProps({ className: 'dropzone' })}
//             style={{ 
//               width: '70vw',
//               display: 'flex',
//               flexDirection: 'column', 
//               justifyContent: 'center', 
//               alignItems: 'center', 
//               border: '2px dashed #cccccc', 
//               borderRadius: '5px',
//               padding: '20px', 
//               textAlign: 'center',
//               opacity: isDisabled ? 0.5 : 1, // Change opacity if disabled
//               pointerEvents: isDisabled ? 'none' : 'auto' // Disable pointer events if disabled
//             }}
//           >
//             <input {...getInputProps()} />
//             {isDragActive ? (
//               <>
//                 <FaCloudUploadAlt size={100} />
//                 <Text fontSize='2xl'>Drop the files here...</Text>
//               </>
//             ) : acceptedFiles.length === 0 ? (
//               <>
//                 <FaCloudUploadAlt size={100} />
//                 <Text fontSize='2xl'>{message || 'Upload Claims and Evidence'}</Text>
//               </>
//             ) : (
//               <>
//                 <List spacing={3}>
//                   {files}
//                 </List>
//                 {uploading && (
//                   <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
//                     <Spinner size="sm" />
//                     <Text fontSize="lg" mt={2}>Uploading...</Text>
//                   </Box>
//                 )}
//               </>
//             )}
//           </div>
//         </section>
//       </Card>
//     </Box>
//   );
// };

// export default UploadButton;



import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Card,
  Text,
  List,
  ListItem,
  Box,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import { FaCloudUploadAlt, FaTimes } from 'react-icons/fa';

interface UploadButtonProps {
  onFilesChange: (files: File[]) => void;
  message?: string; // Optional prop for custom message
  isDisabled?: boolean; // Optional prop to disable the dropzone
  uploading?: boolean; // Optional prop to show loading indicator
}

const UploadButton: React.FC<UploadButtonProps> = ({ onFilesChange, message, isDisabled, uploading }) => {
  const [files, setFiles] = useState<File[]>([]);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = [...files, ...acceptedFiles];
      setFiles(newFiles);
      onFilesChange(newFiles);
    },
    disabled: isDisabled, // Use the isDisabled prop to disable the dropzone
    accept: {
      'application/pdf': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    }, // Accept only PDF and DOCX files
  });

  const handleRemoveFile = (fileToRemove: File, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent file explorer from opening
    const newFiles = files.filter((file) => file !== fileToRemove);
    setFiles(newFiles);
    onFilesChange(newFiles);
  };

  const fileList = files.map((file: File) => (
    <ListItem key={file.name} display="flex" alignItems="center">
      <Box flex="1">
        {file.name} - {file.size} bytes
      </Box>
      <IconButton
        icon={<FaTimes />}
        aria-label="Remove file"
        size="sm"
        onClick={(event) => handleRemoveFile(file, event)}
      />
    </ListItem>
  ));

  return (
    <Box>
      <Card alignItems={"center"}>
        <section className="container">
          <div
            {...getRootProps({ className: 'dropzone' })}
            style={{ 
              width: '70vw',
              display: 'flex',
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              border: '2px dashed #cccccc', 
              borderRadius: '5px',
              padding: '20px', 
              textAlign: 'center',
              opacity: isDisabled ? 0.5 : 1, // Change opacity if disabled
              pointerEvents: isDisabled ? 'none' : 'auto' // Disable pointer events if disabled
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <FaCloudUploadAlt size={100} />
                <Text fontSize='2xl'>Drop the files here...</Text>
              </>
            ) : files.length === 0 ? (
              <>
                <FaCloudUploadAlt size={100} />
                <Text fontSize='2xl'>{message || 'Upload Claims and Evidence'}</Text>
              </>
            ) : (
              <>
                <Text fontSize={"xl"}>Click to upload more files</Text>
                <br />
                <List spacing={3}>
                  {fileList}
                </List>
                {uploading && (
                  <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                    <Spinner size="sm" />
                    <Text fontSize="lg" mt={2}>Uploading...</Text>
                  </Box>
                )}
              </>
            )}
          </div>
        </section>
      </Card>
    </Box>
  );
};

export default UploadButton;

