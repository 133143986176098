import { useState, useEffect, useRef } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  // Text,
  Heading,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
// import UploadButton from "../components/UploadButton";
// import axios from 'axios';
import Markdown from "react-markdown";
import UploadButtonScripted from "../components/Scripted";
import { motion, AnimatePresence } from 'framer-motion';
import ReactGA from 'react-ga4';

export const JudgementPagedev = () => {

  const [showDemoButtonDisabled, setShowDemoButtonDisabled] = useState(false)

  const [stage1FilesScripted, setStage1FilesScripted] = useState("");
  const [stage1Output, setStage1Output] = useState<string>("");
  const [stage1SubmitLoading, setStage1SubmitLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isAnimatingStage1, setIsAnimatingStage1] = useState(false);
  const [isAnimatingStage1Button, setIsAnimatingStage1Button] = useState(false);
  const modalPromiseRef = useRef<(value?: unknown) => void>();
  const uploadButtonRef = useRef<HTMLDivElement>(null);
  const [uploadButtonPosition, setUploadButtonPosition] = useState<{ x: number; y: number } | null>(null);
  const Stage1SubmitButtonRef = useRef<HTMLButtonElement>(null);
  const [Stage1SubmitButtonPosition, setStage1SubmitButtonPosition] = useState<{ x: number; y: number } | null>(null);
  const [showStage1, setShowStage1] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null);

  const [stage2FilesScripted, setStage2FilesScripted] = useState("");
  const [showStage2, setShowStage2] = useState(false)
  // const [stage2Files, setStage2Files] = useState<File[]>([]);
  const [stage2Output, setStage2Output] = useState<string>("");
  const [stage2SubmitLoading, setStage2SubmitLoading] = useState(false)
  // const [stage2SubmitDisabled, setStage2SubmitDisabled] = useState(false)
  const [isAnimatingStage2, setIsAnimatingStage2] = useState(false);
  const [isAnimatingStage2Button, setIsAnimatingStage2Button] = useState(false);
  const uploadButton2Ref = useRef<HTMLDivElement>(null);
  const [uploadButton2Position, setUploadButton2Position] = useState<{ x: number; y: number } | null>(null);
  const Stage2SubmitButtonRef = useRef<HTMLButtonElement>(null);
  const [Stage2SubmitButtonPosition, setStage2SubmitButtonPosition] = useState<{ x: number; y: number } | null>(null);

  const [stage4_9Output, setStage4_9Output] = useState<string>("");

  const [stage4_9_5Output, setStage4_9_5Output] = useState<string>("");

  const [stage5Output, setStage5Output] = useState<string>("");

  const [stage6Output, setStage6Output] = useState<string>("");

  const [stage7Output, setStage7Output] = useState<string>("");

  const toast = useToast()

  function addToast() {
    // toast({ description: 'some text' })
    toast({
      title: 'JudgeAI is analysing facts',
      description: "JudgeAI typically takes a minute to respond",
      status: 'loading',
      duration: null,
      // isClosable: true,
    })
  }

  function closeAll() {
    toast.closeAll()
  }



  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/judgement_demo", title: "JudgementDemo" });
  }, []); // Empty dependency array means this runs only on mount


  useEffect(() => {

    if (!isOpen && modalPromiseRef.current) {
      modalPromiseRef.current();
      modalPromiseRef.current = undefined;
    }
  }, [isOpen]);




  const handleDemo = async () => {
    onOpen(); // Open the modal at the start
    setShowStage1(true)
    await new Promise((resolve) => {
      modalPromiseRef.current = resolve;
    });

    setShowDemoButtonDisabled(true)

    if (uploadButtonRef.current) {
        const rect = uploadButtonRef.current.getBoundingClientRect();
        setUploadButtonPosition({ x: rect.left, y: rect.top });
      }

    console.log("in");
    setIsAnimatingStage1(true);
    setTimeout(() => {
      setIsAnimatingStage1(false);
      setStage1FilesScripted("Lawsuit.pdf");
      handleSubmitStage1();
    }, 2000); // Total animation duration (in ms)

  };


const handleSubmitStage1 = async () => {
  if (Stage1SubmitButtonRef.current) {
    const rect = Stage1SubmitButtonRef.current.getBoundingClientRect();
    setStage1SubmitButtonPosition({
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2 + window.scrollY
    });
  }

  setIsAnimatingStage1Button(true);
  setTimeout(() => {
    setIsAnimatingStage1Button(false);
    setStage1SubmitLoading(true);
    addToast();

    // Simulate an async operation, such as an API request
    setTimeout(() => {
      setStage1Output(
`
name of parties: Ivanov Ivan Ivanovich (Plaintiff) vs. Johnson James (Defendant)
date of claim filing: Not specified in the document
amount of claim: 100,000 AED
Claim validity: True

Circumstance:
- Principal Circumstance: Non-payment of the second installment of 100,000 AED by the Defendant as per the vehicle purchase agreement.
- Derivative Circumstance: The agreement for the vehicle purchase and the agreed payment schedule.
- Indirectly Derivative Circumstance: The delivery of the vehicle to the Defendant and the payment of the first installment.
- Ancillary Circumstance: The legal obligations under the UAE Civil Transactions Law regarding contract performance and payment.

Analysis of circumstances:
- Categories of circumstances are referred to by the Claimant in the claim statement as confirmed by evidence: The claimant refers to the principal circumstance of non-payment and supports this with derivative circumstances such as the agreement and payment schedule, indirectly derivative circumstances like the delivery of the vehicle and payment of the first installment, and ancillary circumstances related to legal obligations.
- Most important circumstance: The non-payment of the second installment of 100,000 AED by the Defendant.

Evidence indicated in the claim and other documents:
- Evidence 1: Copy of the vehicle purchase agreement dated January 1, 2024, related fact(s): Agreement for the purchase of a BMW X5, 2022 model, for 250,000 AED with a payment schedule.
- Evidence 2: Copy of the vehicle delivery certificate, related fact(s): Confirmation of the vehicle's delivery to the Defendant on January 1, 2024.
- Evidence 3: Copy of the receipt for the first installment payment of 150,000 AED, related fact(s): Confirmation of the Defendant's payment of the first installment within the agreed timeframe.
`
      );
      setStage1SubmitLoading(false);

      closeAll();
      setShowStage2(true);

      // Scroll to bottom after 1 second delay
      setTimeout(() => {
        scrollToSection("bottom")

        setTimeout(() => {

          // if (uploadButton2Ref.current) {
          //   const rect = uploadButton2Ref.current.getBoundingClientRect();
          //   setUploadButton2Position({ x: rect.left, y: rect.top });
          // }
          if (uploadButton2Ref.current) {
            const rect = uploadButton2Ref.current.getBoundingClientRect();
            setUploadButton2Position({
              x: rect.left + window.scrollX,
              y: rect.top + window.scrollY
            });
          }

          setIsAnimatingStage2(true);
          setTimeout(() => {
            setIsAnimatingStage2(false);
            setStage2FilesScripted("Defendant_Response.pdf");

            handleSubmitStage2()

          }, 2000); // Total animation duration (in ms)

        }, 1000)


      }, 1000);

    }, 2000); // Simulate the duration of the async operation
  }, 2000); // Total animation duration (in ms)
};



const handleSubmitStage2 = async () => {
  if (Stage2SubmitButtonRef.current) {
    const rect = Stage2SubmitButtonRef.current.getBoundingClientRect();
    setStage2SubmitButtonPosition({
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2 + window.scrollY
    });
  }

  setIsAnimatingStage2Button(true);
  setTimeout(() => {
    setIsAnimatingStage2Button(false);
    setStage2SubmitLoading(true);
    addToast();

    // Simulate an async operation, such as an API request
    setTimeout(() => {
      setStage2Output(
`
Response validity: Not applicable as the defendant's defenses or evidence against the claim are not provided in the text.

Claim needs rechecking: False

Prima Facie refuted: Not applicable as the text does not provide the defendant's specific rebuttal or evidence against the prima facie case established by the claimant.

Circumstance:
- Principal Circumstance: Non-payment of the second installment of 100,000 AED by the Defendant.
- Derivative Circumstance: Delivery of the Vehicle to the Defendant and the Defendant's payment of the first installment.
- Indirectly Derivative Circumstance: Absence of a record of the second installment payment in the Plaintiff's bank account.
- Ancillary Circumstance: Plaintiff sent written notices to the Defendant regarding the outstanding payment.

Analysis of circumstances:
- Categories of circumstances are referred to by the Defendant in the claim statement as confirmed by evidence: Not applicable as the defendant's specific defenses or references to circumstances are not provided in the text.
- Most important circumstance: Non-payment of the second installment of 100,000 AED by the Defendant.

Evidence indicated in the response and other documents:
- Evidence 1 (Claimant): Copy of the vehicle purchase agreement dated January 1, 2024, related fact(s): Establishment of the vehicle purchase agreement and agreed payment terms vs Evidence 1 (Defendant): Not provided.
- Evidence 2 (Claimant): Copy of the vehicle delivery certificate, related fact(s): Confirmation of the vehicle's delivery to the Defendant vs Evidence 2 (Defendant): Not provided.
- Evidence 3 (Claimant): Copy of the receipt for the first installment payment of 150,000 AED, related fact(s): Confirmation of the Defendant's payment of the first installment vs Evidence 3 (Defendant): Not provided.
- Evidence 4 (Claimant): Written notices sent by the Plaintiff to the Defendant on March 1, 2024, March 15, 2024, and April 1, 2024, related fact(s): Attempts by the Plaintiff to remind the Defendant of the outstanding payment vs Evidence 4 (Defendant): Not provided.

The analysis is based on the information provided in the text, which does not include specific defenses or evidence presented by the defendant against the claimant's allegations and evidence. Therefore, the response validity and prima facie refutation cannot be accurately assessed without additional details from the defendant's side.
`
      );
      

      
      // setShowStage2(true);
      
      // Scroll to bottom after 1 second delay
      setTimeout(() => {
        scrollToSection("bottom")
        setStage4_9Output(
`
Given the information provided and adhering to the criteria for evidence strength ratings:

- Evidence 1 (Claimant): Copy of the vehicle purchase agreement dated January 1, 2024, strength: Medium, related fact(s): Establishment of the vehicle purchase agreement and agreed payment terms. 
vs 
Evidence 1 (Defendant): Not provided in the text.

Rationale: The vehicle purchase agreement, assuming it is signed by both the Plaintiff (Ivanov Ivan Ivanovich) and the Defendant (Johnson James), qualifies as medium strength evidence. It is a document containing signatures from both disputing parties, providing a higher level of credibility.

- Evidence 2 (Claimant): Copy of the vehicle delivery certificate, strength: Medium, related fact(s): Confirmation of the vehicle's delivery to the Defendant.
vs 
Evidence 2 (Defendant): Not provided in the text.

Rationale: The vehicle delivery certificate, if signed or acknowledged by both parties or an independent third party (such as a delivery service or witness), would be considered medium strength evidence. It verifies the fulfillment of a key condition of the agreement by the Plaintiff.

- Evidence 3 (Claimant): Copy of the receipt for the first installment payment of 150,000 AED, strength: Medium, related fact(s): Confirmation of the Defendant's payment of the first installment.
vs 
Evidence 3 (Defendant): Not provided in the text.

Rationale: The receipt for the first installment payment, if acknowledged by both parties, serves as medium strength evidence. It confirms the Defendant's compliance with part of the financial obligations under the vehicle purchase agreement.

- Evidence 4 (Claimant): Written notices sent by the Plaintiff to the Defendant on March 1, 2024, March 15, 2024, and April 1, 2024, strength: Weak, related fact(s): Attempts by the Plaintiff to remind the Defendant of the outstanding payment.
vs 
Evidence 4 (Defendant): Not provided in the text.

Rationale: Written notices sent by the Plaintiff to the Defendant are considered weak evidence. These documents are prepared and signed solely by one party (the Plaintiff) without any verification or acknowledgment from the Defendant or an independent third party. They demonstrate the Plaintiff's attempts to communicate but do not independently verify the claims made within those communications.

In this analysis, the Defendant has not provided any evidence for comparison. Therefore, the assessment is based solely on the Claimant's provided evidence and the established criteria for evidence strength.
`
        )

        setTimeout(() => {
          scrollToSection("bottom")

          setStage4_9_5Output(
`
Given the provided text and instructions, it appears there is no direct evidence provided by the Defendant to analyze against the Plaintiff's evidence. Therefore, the analysis will focus solely on the Plaintiff's evidence as presented, acknowledging the absence of Defendant's evidence for a direct comparison.

### Evidence Link 1

**Plaintiff's Evidence:**
- Description: Copy of the vehicle purchase agreement dated January 1, 2024.
- Direct Fact: The establishment of a vehicle purchase agreement between Ivanov Ivan Ivanovich and Johnson James for a BMW X5, 2022 model, with agreed payment terms.

**Defendant's Evidence:**
- Description: Not provided in the text.
- Direct Fact: N/A

**Selection:** Plaintiff's Evidence
- Detailed Fact Description: A vehicle purchase agreement was established on January 1, 2024, between Ivanov Ivan Ivanovich and Johnson James for the purchase of a BMW X5, 2022 model, VIN No. WBA5A7C56FG123456, with a total purchase price of 250,000 AED to be paid in two installments.
- Contradictory Fact: N/A due to the absence of Defendant's evidence.

### Evidence Link 2

**Plaintiff's Evidence:**
- Description: Copy of the vehicle delivery certificate.
- Direct Fact: Confirmation of the vehicle's delivery to Johnson James on January 1, 2024.

**Defendant's Evidence:**
- Description: Not provided in the text.
- Direct Fact: N/A

**Selection:** Plaintiff's Evidence
- Detailed Fact Description: The vehicle, a BMW X5, 2022 model, was delivered to Johnson James on January 1, 2024, fulfilling part of the Plaintiff's obligations under the purchase agreement.
- Contradictory Fact: N/A due to the absence of Defendant's evidence.

### Evidence Link 3

**Plaintiff's Evidence:**
- Description: Copy of the receipt for the first installment payment of 150,000 AED.
- Direct Fact: Confirmation of Johnson James's payment of the first installment.

**Defendant's Evidence:**
- Description: Not provided in the text.
- Direct Fact: N/A

**Selection:** Plaintiff's Evidence
- Detailed Fact Description: Johnson James made the first installment payment of 150,000 AED within the agreed timeframe, as evidenced by the receipt.
- Contradictory Fact: N/A due to the absence of Defendant's evidence.

### Evidence Link 4

**Plaintiff's Evidence:**
- Description: Written notices sent by the Plaintiff to the Defendant on March 1, 2024, March 15, 2024, and April 1, 2024.
- Direct Fact: Attempts by Ivanov Ivan Ivanovich to remind Johnson James of the outstanding second installment payment of 100,000 AED.

**Defendant's Evidence:**
- Description: Not provided in the text.
- Direct Fact: N/A

**Selection:** Plaintiff's Evidence
- Detailed Fact Description: Ivanov Ivan Ivanovich sent written notices to Johnson James on March 1, 2024, March 15, 2024, and April 1, 2024, in an attempt to remind and secure the outstanding second installment payment of 100,000 AED for the vehicle purchase agreement.
- Contradictory Fact: N/A due to the absence of Defendant's evidence.

In conclusion, due to the absence of Defendant's evidence for direct comparison, the analysis solely relies on the Plaintiff's provided evidence, which establishes a series of actions and agreements between the Plaintiff and the Defendant regarding the vehicle purchase agreement.
`
          )

          setTimeout(() => {

            scrollToSection("bottom")
            setStage5Output(
`
### List of "Selection" Facts:

1. **Vehicle Purchase Agreement Establishment**: A vehicle purchase agreement was established on January 1, 2024, between Ivanov Ivan Ivanovich (Plaintiff) and Johnson James (Defendant) for the purchase of a BMW X5, 2022 model, VIN No. WBA5A7C56FG123456, with a total purchase price of 250,000 AED to be paid in two installments.

2. **Vehicle Delivery**: The vehicle, a BMW X5, 2022 model, was delivered to Johnson James on January 1, 2024, fulfilling part of the Plaintiff's obligations under the purchase agreement.

3. **First Installment Payment**: Johnson James made the first installment payment of 150,000 AED within the agreed timeframe, as evidenced by the receipt.

4. **Written Notices for Outstanding Payment**: Ivanov Ivan Ivanovich sent written notices to Johnson James on March 1, 2024, March 15, 2024, and April 1, 2024, in an attempt to remind and secure the outstanding second installment payment of 100,000 AED for the vehicle purchase agreement.

### Additional Compatible Facts:

- **No Record of Second Installment Payment**: There is an absence of a record of the second installment payment in the Plaintiff's bank account, indirectly supporting the claim of non-payment.

### Pure Chronology of Facts - Case Fabula:

1. **Event**: Establishment of the vehicle purchase agreement.
- **Date**: January 1, 2024
- **Participants**: Ivanov Ivan Ivanovich (Plaintiff) and Johnson James (Defendant)
- **Location**: Not explicitly stated

2. **Event**: Delivery of the BMW X5, 2022 model, to Johnson James.
- **Date**: January 1, 2024
- **Participants**: Ivanov Ivan Ivanovich (Plaintiff) and Johnson James (Defendant)
- **Location**: Not explicitly stated

3. **Event**: Payment of the first installment of 150,000 AED by Johnson James.
- **Date**: Within the agreed timeframe from January 1, 2024
- **Participants**: Johnson James (Defendant)
- **Location**: Not explicitly stated

4. **Event**: Sending of written notices regarding the outstanding second installment payment.
- **Dates**: March 1, 2024; March 15, 2024; April 1, 2024
- **Participants**: Ivanov Ivan Ivanovich (Plaintiff)
- **Location**: Not explicitly stated

5. **Event**: Absence of the second installment payment record in the Plaintiff's bank account.
- **Date**: Post-first installment payment, before and including the dates of written notices
- **Participants**: Ivanov Ivan Ivanovich (Plaintiff)
- **Location**: Not explicitly stated
`
            )

            setTimeout(() => {
              scrollToSection("bottom")
              setStage6Output(
`
1. Primary Purpose of the Contract
The primary purpose of the contract is to facilitate the sale and transfer of ownership of a BMW X5, 2022 model, from Ivanov Ivan Ivanovich (Plaintiff) to Johnson James (Defendant) in exchange for a total purchase price of 250,000 AED, to be paid in two installments.

2. Key Terms of the Contract
The key terms of the contract, without which the contract would not make sense, are as follows:
- **Key Term 1**: Total Purchase Price - The total purchase price of 250,000 AED for the vehicle.
- **Key Term 2**: Payment in Installments - The agreement to pay the total purchase price in two installments, with the first installment being 150,000 AED and the second installment being 100,000 AED.
- **Key Term 3**: Delivery of Vehicle - The obligation of the Plaintiff to deliver the BMW X5, 2022 model, to the Defendant upon the agreement.

3. External Circumstances and Facts
The external circumstances and facts that affect the ability of the parties to fulfill their obligations are as follows:
- **Fact 1**: Banking and Financial Systems - The functionality of banking systems to process and record payments.
- **Fact 2**: Communication Systems - The reliability of postal and electronic communication systems for sending and receiving notices.
- **Fact 3**: Regulatory and Legal Compliance - Compliance with any legal requirements for the sale and transfer of vehicle ownership.

4. Ideal Model of Parties' Behavior: Chronology
Preparation Phase
- **Event**: Signing of the contract.
- **Actions by Party A (Plaintiff)**: Ensure the vehicle is in the agreed condition and all necessary documents for the sale are prepared.
- **Actions by Party B (Defendant)**: Verify contract terms, confirm agreement, and prepare for the first installment payment.

Initial Phase of Obligation Fulfillment
- **Event**: Payment of the first installment and delivery of the vehicle.
- **Actions by Party A**: Deliver the vehicle to the Defendant and provide all necessary documents for ownership transfer.
- **Actions by Party B**: Make the first installment payment and confirm receipt of the vehicle.

Intermediate Actions
- **Event**: Reminder of the second installment payment.
- **Actions by Party A**: Send written notices to the Defendant reminding of the second installment due dates.
- **Actions by Party B**: Acknowledge receipt of the notices and prepare for the second installment payment.

Ensuring External Conditions
- **Event**: Monitoring of external circumstances (e.g., banking operations).
- **Actions by Party A**: Ensure that there are no banking issues that could prevent the receipt of the second installment.
- **Actions by Party B**: Confirm that the payment method chosen is operational and inform the Plaintiff of any issues.

Completion of Obligations
- **Event**: Payment of the second installment.
- **Actions by Party A**: Confirm receipt of the second installment and complete the transfer of vehicle ownership.
- **Actions by Party B**: Make the second installment payment and ensure all documents related to the vehicle ownership transfer are received.

Final Phase
- **Event**: Conclusion and review of the contract fulfillment.
- **Actions by Party A**: Provide a final confirmation of the contract fulfillment and resolve any outstanding issues.
- **Actions by Party B**: Review the fulfillment of the contract, confirm satisfaction with the vehicle, and close the contract.
`
              )

              setTimeout(() => {
               
                scrollToSection("bottom")
                setStage7Output(
`
# Judgement
JudgeAI Court
[2024-07-07]
Ivanov Ivan Ivanovich vs. Johnson James
Case Title: Claim for Recovery of Debt Under a Vehicle Purchase Agreement

### Introduction:
This case revolves around the claim filed by Ivanov Ivan Ivanovich (Plaintiff) against Johnson James (Defendant) concerning an alleged breach of a vehicle purchase agreement. The Plaintiff asserts that the Defendant has failed to make the second installment payment of 100,000 AED for a BMW X5, 2022 model, as per the terms agreed upon in the vehicle purchase agreement dated January 1, 2024. The Plaintiff has provided evidence including the vehicle purchase agreement, vehicle delivery certificate, receipt for the first installment payment, and copies of written notices sent to the Defendant regarding the outstanding payment. The Defendant's position, objections, or evidence in response to the claim have not been provided in the text.

### Description of the Evidence Analysis Process and Compilation of the Final Case Narrative Based on the Evidence Analysis:
The evidence was meticulously analyzed to establish the factual background of the case. The vehicle purchase agreement, delivery certificate, and receipt for the first installment payment were scrutinized to confirm the agreement's existence, the vehicle's delivery, and the first installment's payment. The written notices were considered as attempts by the Plaintiff to remind the Defendant of the outstanding payment. This analysis led to a narrative that aligns with the Plaintiff's claim of fulfilling their obligations under the agreement and the Defendant's failure to make the second installment payment.

### Detailed Account of Deviations from Ideal Behavior:
The primary deviation from the ideal behavior model involves the Defendant's failure to make the second installment payment of 100,000 AED by the agreed deadline. According to the ideal behavior model, the Defendant should have acknowledged the receipt of the notices and prepared for the second installment payment, which did not occur.

### Analysis of Each Identified Deviation:
The deviation directly affects the Plaintiff's right to receive the agreed payment in accordance with the vehicle purchase agreement. This deviation is caused by the actions of the Defendant, with no indication that the Plaintiff violated any related right of the Defendant that could justify the non-payment. The Defendant's failure to make the payment does not align with the Nash equilibrium, as there was no alternative strategy that the Plaintiff could have taken to improve their outcome without worsening the results for the Defendant. The Defendant's non-payment is recognized as a violation of the agreement terms.

### Conclusion:
Based on the evidence provided and the analysis of the deviations from the ideal behavior model, the claim requirements are satisfied in full. The Defendant, Johnson James, is ordered to pay the outstanding debt under the vehicle purchase agreement in the amount of 100,000 AED. Additionally, the Defendant is ordered to pay interest on the outstanding amount as prescribed by UAE law, from March 1, 2024, until the date of actual payment, along with the Plaintiff's legal costs and attorney fees. This decision ensures that the Plaintiff's claim requirements are fully met, with no unresolved claims remaining.
`
                )

                setTimeout(() => {
                  scrollToSection("bottom")
                  setStage2SubmitLoading(false);
                  closeAll();
                }, 2000)
              
              }, 2000)

            }, 2000)

          }, 2000)

        }, 2000)

        
      }, 1000);

    }, 2000); // Simulate the duration of the async operation
  }, 2000); // Total animation duration (in ms)
};

// console.log(
//   showStage2,
//   handleStage1FilesChange,
//   handleStage2FilesChange,
//   uploadButton2Ref,
//   uploadButton2Position,
//   Stage1SubmitButtonRef,
//   Stage2SubmitButtonPosition,
//   setStage2SubmitButtonPosition,
//   setUploadButton2Position,
//   Stage2SubmitButtonRef,
// )


  return (
    <Box ref={containerRef}>
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box paddingTop={"40px"} paddingBottom={"40px"}>
        <VStack spacing={"40px"}>
        <Modal isOpen={isOpen} onClose={onClose} size={{base: "xs", md:"md"}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>JudgeAI Demo Version</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
                This is a demo version of JudgeAI designed to show capabilites of JudgeAI's algorithm and how to use JudgeAI.
            </p>
            <p>
                First a lawsuit is uploaded to JudgeAI. JudgeAI extracts the facts from lawsuit and prompts defendant to upload
                response. JudgeAI than analyzes narrative of both parties, may ask for further clarifications, constitues a case Fable, 
                developes an ideal behaviour model and generates Judgement.
            </p>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
            <Button
            onClick={handleDemo}
            colorScheme="teal"
            variant={"outline"}
            size={"lg"}
            isDisabled={showDemoButtonDisabled}
            >
                Start Demo
            </Button>
            <AnimatePresence>
        {isAnimatingStage1 && uploadButtonPosition && (
          <motion.div
            // initial={{ opacity: 0, x: 0, y: 0 }}
            initial={{ opacity: 0, x: -50, y: window.innerHeight - 50 }}
            // animate={{ opacity: 1, x: 100, y: 100 }}
            animate={{ opacity: 1, x: uploadButtonPosition.x, y: uploadButtonPosition.y }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
            style={{ fontSize: '50px', position: 'absolute', top: '0px', left: '0px' }}
          >
            📄
          </motion.div>
        )}
      </AnimatePresence>
        {(showStage1) && (<Box>
          <VStack spacing={"10px"}>
          <Box ref={uploadButtonRef}>
            <UploadButtonScripted 
            onFilesChange={(files) => console.log(files)} 
            isDisabled={false} 
            initialFileName={stage1FilesScripted} 
            />
        </Box>
          <Button ref={Stage1SubmitButtonRef} colorScheme="teal" onClick={handleSubmitStage1} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          <AnimatePresence>
        {isAnimatingStage1Button && Stage1SubmitButtonPosition && (
          <motion.div
            // initial={{ opacity: 0, x: 0, y: 0 }}
            initial={{ opacity: 0, x: -50, y: window.innerHeight - 50 }}
            // animate={{ opacity: 1, x: 100, y: 100 }}
            animate={{ opacity: 1, x: Stage1SubmitButtonPosition.x, y: Stage1SubmitButtonPosition.y }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
            style={{ fontSize: '50px', position: 'absolute', top: '0px', left: '0px' }}
          >
            👆
          </motion.div>
        )}
      </AnimatePresence>
          </VStack>
        </Box>)}
          {stage1Output && (
            <>
            <Box
              p={10}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from claim</Heading>
              <Markdown>{stage1Output}</Markdown>
            </Box>
            </>
          )}
          {showStage2 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <Box ref={uploadButton2Ref}>
                <UploadButtonScripted 
                    onFilesChange={(files) => console.log(files)} 
                    isDisabled={false} 
                    initialFileName={stage2FilesScripted} 
                    message="Defendant is directed to upload response" 
                  />
                  </Box>
                  <AnimatePresence>
                {isAnimatingStage2 && uploadButton2Position && (
                  <motion.div
                    // initial={{ opacity: 0, x: 0, y: 0 }}
                    initial={{ opacity: 0, x: -50, y: window.innerHeight - 50 }}
                    // animate={{ opacity: 1, x: 100, y: 100 }}
                    animate={{ opacity: 1, x: uploadButton2Position.x, y: uploadButton2Position.y }}
                    // exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                    style={{ fontSize: '50px', position: 'absolute', top: '0px', left: '0px' }}
                  >
                    📄
                  </motion.div>
                )}
              </AnimatePresence>
                <Button ref={Stage2SubmitButtonRef} colorScheme="teal" onClick={handleSubmitStage2} isLoading={stage2SubmitLoading}>
                  Submit
                </Button>
                <AnimatePresence>
                  {isAnimatingStage2Button && Stage2SubmitButtonPosition && (
                    <motion.div
                      // initial={{ opacity: 0, x: 0, y: 0 }}
                      initial={{ opacity: 0, x: -50, y: window.innerHeight - 50 }}
                      // animate={{ opacity: 1, x: 100, y: 100 }}
                      animate={{ opacity: 1, x: Stage2SubmitButtonPosition.x, y: Stage2SubmitButtonPosition.y }}
                      // exit={{ opacity: 0 }}
                      transition={{ duration: 2 }}
                      style={{ fontSize: '50px', position: 'absolute', top: '0px', left: '0px' }}
                    >
                      👆
                    </motion.div>
                  )}
                </AnimatePresence>
              </VStack>
            </Box>
          )}
          {stage2Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from defendant's response</Heading>
              <Markdown>{stage2Output}</Markdown>
            </Box>
            </>
          )}
          {stage4_9Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Evidence Analysis</Heading>
              <Markdown>{stage4_9Output}</Markdown>
            </Box>
            </>
          )}
          {stage4_9_5Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Evidence Conclusion</Heading>
              <Markdown>{stage4_9_5Output}</Markdown>
            </Box>
            </>
          )}
          {stage5Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Case Fable</Heading>
              <Markdown>{stage5Output}</Markdown>
            </Box>
            </>
          )}
          {stage6Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Ideal chronology</Heading>
              <Markdown>{stage6Output}</Markdown>
            </Box>
            </>
          )}
          {stage7Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Final Judgement</Heading>
              <Markdown>{stage7Output}</Markdown>
            </Box>
            </>
          )}
        </VStack>
        <div id="bottom">
        </div>
      </Box>
    </ChakraProvider>
    </Box>
  );
};

export default JudgementPagedev;