// import React from 'react';
// import { useDropzone } from 'react-dropzone';
// import { 
//   Card,
//   Text,
//   List,
//   ListItem,
//   Box,
// } from '@chakra-ui/react';
// import { FaCloudUploadAlt } from 'react-icons/fa';

// interface UploadButtonProps {
//     onFilesChange: (files: File[]) => void;
//     message?: string; // Optional prop for custom message
//     isDisabled?: boolean; // Optional prop to disable the dropzone
//     initialFileName?: string; // Optional prop for initial file name
//   }
  
//   const UploadButtonScripted: React.FC<UploadButtonProps> = ({ onFilesChange, message, isDisabled, initialFileName }) => {
//     const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
//       onDrop: (acceptedFiles) => onFilesChange(acceptedFiles),
//       disabled: isDisabled, // Use the isDisabled prop to disable the dropzone
//     });
  
//     const files = acceptedFiles.map((file: File) => (
//       <ListItem key={file.name}>
//         {file.name} - {file.size} bytes
//       </ListItem>
//     ));
  
//     return (
//       <Box>
//         <Card alignItems={"center"}>
//           <section className="container">
//             <div
//               {...getRootProps({ className: 'dropzone' })}
//               style={{ 
//                 width: '70vw',
//                 display: 'flex',
//                 flexDirection: 'column', 
//                 justifyContent: 'center', 
//                 alignItems: 'center', 
//                 border: '2px dashed #cccccc', 
//                 borderRadius: '5px',
//                 padding: '20px', 
//                 textAlign: 'center',
//                 opacity: isDisabled ? 0.5 : 1, // Change opacity if disabled
//                 pointerEvents: isDisabled ? 'none' : 'auto' // Disable pointer events if disabled
//               }}
//             >
//               <input {...getInputProps()} />
//               {isDragActive ? (
//                 <>
//                   <FaCloudUploadAlt size={100} />
//                   <Text fontSize='2xl'>Drop the files here...</Text>
//                 </>
//               ) : initialFileName != "" ? (
//                 <>
//                   {/* <FaCloudUploadAlt size={100} />
//                   <Text fontSize='2xl'>{message || 'Upload Claims and Evidence'}</Text> */}
//                   {initialFileName && (
//                     <List spacing={3}>
//                       <ListItem>{initialFileName}</ListItem>
//                     </List>
//                   )}
//                 </>
//               ) : (
//                 // <List spacing={3}>
//                 //   {files}
//                 // </List>
//                 <>
//                 <FaCloudUploadAlt size={100} />
//                   <Text fontSize='2xl'>{message || 'Upload Claims and Evidence'}</Text>
//                   </>
//               )}
//             </div>
//           </section>
//         </Card>
//       </Box>
//     );
//   };
  

// export default UploadButtonScripted;


import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, Text, List, ListItem, Box } from '@chakra-ui/react';
import { FaCloudUploadAlt } from 'react-icons/fa';

interface UploadButtonProps {
  onFilesChange: (files: File[]) => void;
  message?: string; // Optional prop for custom message
  isDisabled?: boolean; // Optional prop to disable the dropzone
  initialFileName?: string; // Optional prop for initial file name
}

const UploadButtonScripted: React.FC<UploadButtonProps> = ({ onFilesChange, message, isDisabled, initialFileName }) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onFilesChange(acceptedFiles),
    disabled: isDisabled, // Use the isDisabled prop to disable the dropzone
  });

  const files = acceptedFiles.map((file: File) => (
    <ListItem key={file.name}>
      {file.name} - {file.size} bytes
    </ListItem>
  ));

  console.log(files)

  return (
    <Box>
      <Card alignItems={"center"}>
        <section className="container">
          <div
            {...getRootProps({ className: 'dropzone' })}
            style={{
              width: '70vw',
              height: '200px', // Set a fixed height for the dropzone
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '2px dashed #cccccc',
              borderRadius: '5px',
              padding: '20px',
              textAlign: 'center',
              opacity: isDisabled ? 0.5 : 1, // Change opacity if disabled
              pointerEvents: isDisabled ? 'none' : 'auto' // Disable pointer events if disabled
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <FaCloudUploadAlt size={100} />
                <Text fontSize='2xl'>Drop the files here...</Text>
              </>
            ) : initialFileName !== "" ? (
              <>
                {initialFileName && (
                  <List spacing={3}>
                    <ListItem>{initialFileName}</ListItem>
                  </List>
                )}
              </>
            ) : (
              <>
                <FaCloudUploadAlt size={100} />
                <Text fontSize='2xl'>{message || 'Upload Claims and Evidence'}</Text>
              </>
            )}
          </div>
        </section>
      </Card>
    </Box>
  );
};

export default UploadButtonScripted;
