import { useState, useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import UploadButton from "../components/UploadButton";
import axios from 'axios';
import Markdown from "react-markdown";
import {v4 as uuidv4} from 'uuid';
import { useUserAuth } from "../context/userAuthContext";
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ReactGA from 'react-ga4';

export const UaeLawJudgementPage = () => {
  const { user } = useUserAuth();

  const [stage, setStage] = useState(0);
  const [stage1Files, setStage1Files] = useState<File[]>([]);
  const [stage1Output, setStage1Output] = useState<string>("");
  const [stage1SubmitLoading, setStage1SubmitLoading] = useState(false)
  const [stage1SubmitDisabled, setStage1SubmitDisabled] = useState(false)
  const [stage1FileUploading, setStage1FileUploading] = useState(false)

  const [showStage2, setShowStage2] = useState(false)
  const [stage2Files, setStage2Files] = useState<File[]>([]);
  const [stage2Output, setStage2Output] = useState<string>("");
  const [stage2SubmitLoading, setStage2SubmitLoading] = useState(false)
  const [stage2SubmitDisabled, setStage2SubmitDisabled] = useState(false)
  const [stage2FileUploading, setStage2FileUploading] = useState(false)
  
  const [showStage3, setShowStage3] = useState(false);
  const [stage3Files, setStage3Files] = useState<File[]>([]);
  const [stage3Output, setStage3Output] = useState<string>("");
  const [stage3SubmitLoading, setStage3SubmitLoading] = useState(false)
  const [stage3SubmitDisabled, setStage3SubmitDisabled] = useState(false)
  const [stage3FileUploading, setStage3FileUploading] = useState(false)

  const [showStage4, setShowStage4] = useState(false);
  const [stage4Files, setStage4Files] = useState<File[]>([]);
  const [stage4Output, setStage4Output] = useState<string>("");
  const [stage4SubmitLoading, setStage4SubmitLoading] = useState(false)
  const [stage4SubmitDisabled, setStage4SubmitDisabled] = useState(false)
  const [stage4FileUploading, setStage4FileUploading] = useState(false)

  const [claimExtractedText, setClaimExtractedText] = useState<string>("");
  const [defendantExtractedText, setDefendantExtractedText] = useState<string>("");
  const [claimantResponseExtractedText, setClaimantResponseExtractedText] = useState<string>("");
  const [defendantResponseExtractedText, setDefendantResponseExtractedText] = useState<string>("");

  const [showStage4_9, setShowStage4_9] = useState(false)
  const [stage4_9Output, setStage4_9Output] = useState<string>("");

  const [showStage4_9_5, setShowStage4_9_5] = useState(false)
  const [stage4_9_5Output, setStage4_9_5Output] = useState<string>("");

  // const [showStage5_9, setShowStage5_9] = useState(false)
  const [stage5_9Output, setStage5_9Output] = useState<string>("");

  const [showStage5, setShowStage5] = useState(false);
  const [stage5Output, setStage5Output] = useState<string>("");

  const [stage6Output, setStage6Output] = useState<string>("");

  const [stage7Output, setStage7Output] = useState<string>("");

  console.log(stage)
  console.log(user)
  console.log(
    showStage4_9,
    showStage4_9_5,
    showStage5,
    stage7Output
  );

  const [userCredits, setUserCredits] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true);

  const toast = useToast()

  function addToast() {
    // toast({ description: 'some text' })
    toast({
      title: 'JudgeAI is analysing facts',
      description: "JudgeAI typically takes a minute to respond",
      status: 'loading',
      duration: null,
      // isClosable: true,
    })
  }

  function closeAll() {
    toast.closeAll()
  }

  // useEffect for Google Analytics pageview tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/uae_law", title: "UAELawPage" });
  }, []); // Empty dependency array means this runs only on mount

  useEffect(() => {
    const fetchUserData = async () => {
        if (user) { 
        const userEmail = user.email || '';
        setUserEmail(userEmail);
          
        try {
            const userDoc = await getDoc(doc(db, "users", userEmail));
            if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserCredits(userData.credits || 0);
            setLoadingScreen(false)
            } else {
            console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
        }
    };

    fetchUserData();
    }, [user]);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  useEffect(() => {
    if (stage1Output) {
      scrollToSection("bottom");
    }
  }, [stage1Output]);
  
  useEffect(() => {
    if (stage2Output) {
      scrollToSection("bottom");
    }
  }, [stage2Output]);
  
  useEffect(() => {
    if (stage3Output) {
      scrollToSection("bottom");
    }
  }, [stage3Output]);
  
  useEffect(() => {
    if (stage4Output) {
      scrollToSection("bottom");
    }
  }, [stage4Output]);
  
  useEffect(() => {
    if (stage4_9Output) {
      scrollToSection("bottom");
    }
  }, [stage4_9Output]);
  
  useEffect(() => {
    if (stage4_9_5Output) {
      scrollToSection("bottom");
    }
  }, [stage4_9_5Output]);
  
  useEffect(() => {
    if (stage5Output) {
      scrollToSection("bottom");
    }
  }, [stage5Output]);
  
  useEffect(() => {
    if (stage5_9Output) {
      scrollToSection("bottom");
    }
  }, [stage5_9Output]);
  
  useEffect(() => {
    if (stage6Output) {
      scrollToSection("bottom");
    }
  }, [stage6Output]);
  
  useEffect(() => {
    if (stage7Output) {
      scrollToSection("bottom");
    }
  }, [stage7Output]);

  const uploadFilesToFirebase = async (files: File[], stage: number) => {
    const uploadedFiles: { fileName: string; url: string }[] = [];

    for (const file of files) {
      const fileRef = ref(storage, `uae_${userEmail}_${stage}_${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      uploadedFiles.push({ fileName: file.name, url });
    }

    return uploadedFiles;
  };

  const handleStage1FilesChange = async (files: File[]) => {
    setStage1SubmitDisabled(true);
    setStage1SubmitLoading(true);
    setStage1FileUploading(true);
    
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 1);
      setStage1Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage1SubmitDisabled(false);
      setStage1SubmitLoading(false);
      setStage1FileUploading(false);
    }
  };

  const handleStage2FilesChange = async (files: File[]) => {
    setStage2SubmitDisabled(true);
    setStage2SubmitLoading(true);
    setStage2FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 2);
      setStage2Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage2SubmitDisabled(false);
      setStage2SubmitLoading(false);
      setStage2FileUploading(false);
    }
  };
  
  const handleStage3FilesChange = async (files: File[]) => {
    setStage3SubmitDisabled(true);
    setStage3SubmitLoading(true);
    setStage3FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 3);
      setStage3Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage3SubmitDisabled(false);
      setStage3SubmitLoading(false);
      setStage3FileUploading(false);
    }
  };
  
  const handleStage4FilesChange = async (files: File[]) => {
    setStage4SubmitDisabled(true);
    setStage4SubmitLoading(true);
    setStage4FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 4);
      setStage4Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage4SubmitDisabled(false);
      setStage4SubmitLoading(false);
      setStage4FileUploading(false);
    }
  };
  

  const handleSubmitStage1 = async () => {
    addToast()
    setStage1SubmitLoading(true)

    const userRef = doc(db, "users", userEmail);

    setUserCredits(userCredits - 1)

    await updateDoc(userRef, {
      credits: (userCredits - 1)
    });

    const interaction_id = uuidv4()
    console.log(interaction_id)

    if (stage1Files.length > 0) {
      const formData = new FormData();
      stage1Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });

      try {
        // const response = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_1/', formData, {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_1/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setStage1Output(response.data.response);
        setClaimExtractedText(response.data.claim_extracted_text)
        setStage1SubmitLoading(false)
        setStage(response.data.stage);
        if (response.data.stage === 2) {
          setShowStage2(true)
          setStage1SubmitDisabled(true);
        }
      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    closeAll()
  };

  const handleSubmitStage2 = async () => {
    addToast()
    setStage2SubmitLoading(true);
    const formData = new FormData();
    if (stage2Files.length > 0) {
      stage2Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });
    }
    formData.append('input_string', stage1Output);  
    try {
      const response = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_2/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setStage2Output(response.data.response);
      setDefendantExtractedText(response.data.defendant_extracted_text)
      // setStage2SubmitLoading(false);
      setStage2SubmitDisabled(true);
      setStage(2);
      if (response.data.prima_facie_rebutted) {
        setShowStage3(true);
        setStage2SubmitLoading(false)
      } else {
        const stage4_9Input = claimExtractedText + stage1Output + defendantExtractedText + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output;

        const response4_9 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_4_9/', stage4_9Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const stage4_9Output = response4_9.data.response;
        const stage4_9Stage = response4_9.data.stage;

        setStage4_9Output(stage4_9Output);
        setStage(stage4_9Stage);
        setShowStage4_9(true);

        const stage4_9_5Input = claimExtractedText + defendantExtractedText
                              + claimantResponseExtractedText + defendantResponseExtractedText + stage4_9Output;
        const response4_9_5 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_4_9_5/', stage4_9_5Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage4_9_5Output = response4_9_5.data.response;
        const stage4_9_5Stage = response4_9_5.data.stage;
        setStage4_9_5Output(stage4_9_5Output);
        setStage(stage4_9_5Stage);
        setShowStage4_9_5(true)

        const stage5Input = stage1Output + stage2Output + stage3Output + stage4Output + stage4_9Output + stage4_9_5Output;
        const response5 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_5/', stage5Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage5Output = response5.data.response;
        // const stage5Stage = response5.data.stage;
        setStage5Output(response5.data.response);
        setStage(response5.data.stage);
        setShowStage5(true);

        const stage5_9Input = stage1Output;
        console.log(stage5_9Input)
        const response5_9 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_5_9/', stage5_9Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage5_9Output = response5_9.data.response;
        // const stage6Stage = response6.data.stage;
        // setShowStage5_9(true);
        setStage5_9Output(response5_9.data.response);
        // setStage(response5_9.data.stage);
        
        const stage6Input = stage1Output + stage2Output + stage3Output + stage4Output + stage5Output + stage5_9Output;
        const response6 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_6/', stage6Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage6Output = response6.data.response;
        // const stage6Stage = response6.data.stage;
        // setShowStage6(true);
        setStage6Output(response6.data.response);
        // setStage(response6.data.stage);

        console.log(typeof stage5Output)
        const stage7Input = stage1Output + claimExtractedText +  defendantExtractedText + claimantResponseExtractedText + defendantResponseExtractedText + stage5Output + stage6Output + stage5_9Output;
        const response7 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_7/', stage7Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        // const stage7Output = response7.data.response;
        // setShowStage7(true);
        setStage7Output(response7.data.response);  
        setStage2SubmitLoading(false);

        }
    } catch (error) {
      console.error('Error uploading the files', error);
      setStage2SubmitLoading(false);
    }
    closeAll()
  };

  const handleSubmitStage3 = async () => {
    addToast()
    setStage3SubmitLoading(true)
    if (stage3Files.length > 0) {
      const formData = new FormData();
      stage3Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });
      formData.append('input_string', stage1Output+stage2Output); 
      try {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_3/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data);
        setStage3Output(response.data.response);
        setClaimantResponseExtractedText(response.data.claimant_response_extracted_text)
        setStage3SubmitDisabled(true);
        setStage(3);
        setShowStage4(true);
        setStage3SubmitLoading(false);
      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    closeAll()
  };

  const handleSubmitStage3NoObjection = async () => {
    setStage3SubmitLoading(true)
    setStage3Output("\nClaimant clarification to defendant's response: \nNo objection and no response from claimant");
    setClaimantResponseExtractedText("\nClaimant clarification to defendant's response: \nNo objection and no response from claimant");
    setStage3SubmitDisabled(true);
    setStage(3);
    setShowStage4(true);
    setStage3SubmitLoading(false);
  };

  const handleSubmitStage4 = async () => {
    addToast()
    setStage4SubmitLoading(true);
  
    if (stage4Files.length > 0) {
      const formData = new FormData();
      stage4Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });
      formData.append('input_string', stage1Output + stage2Output + stage3Output);
  
      const response = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_4/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const stage4Output = response.data.response;
      const defendantResponseExtractedText = response.data.defendant_response_extracted_text;
      const stage = response.data.stage;
      setStage4Output(stage4Output);
      setDefendantResponseExtractedText(defendantResponseExtractedText);
      setStage4SubmitDisabled(true);
      setStage(stage);
    }
    else {
      const stage4Output = "\nDefendant's response to claimant's clarifications: \nNo objection and no response from defendant";
      const defendantResponseExtractedText = "\nDefendant's response to claimant's clarifications: \nNo objection and no response from defendant";
      const stage = 4.9;
      setStage4Output(stage4Output);
      setDefendantResponseExtractedText(defendantResponseExtractedText);
      setStage4SubmitDisabled(true);
      setStage(stage);
    }

    const stage4_9Input = claimExtractedText + stage1Output + defendantExtractedText + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output;

    const response4_9 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_4_9/', stage4_9Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const stage4_9Output = response4_9.data.response;
    const stage4_9Stage = response4_9.data.stage;

    setStage4_9Output(stage4_9Output);
    setStage(stage4_9Stage);
    setShowStage4_9(true);

    const stage4_9_5Input = claimExtractedText + defendantExtractedText
                          + claimantResponseExtractedText + defendantResponseExtractedText + stage4_9Output;
    const response4_9_5 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_4_9_5/', stage4_9_5Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage4_9_5Output = response4_9_5.data.response;
    const stage4_9_5Stage = response4_9_5.data.stage;
    setStage4_9_5Output(stage4_9_5Output);
    setStage(stage4_9_5Stage);
    setShowStage4_9_5(true)

    const stage5Input = stage1Output + stage2Output + stage3Output + stage4Output + stage4_9Output + stage4_9_5Output;
    const response5 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_5/', stage5Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage5Output = response5.data.response;
    // const stage5Stage = response5.data.stage;
    setStage5Output(response5.data.response);
    setStage(response5.data.stage);
    setShowStage5(true);

    const stage5_9Input = stage1Output;
    const response5_9 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_5_9/', stage5_9Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage5_9Output = response5_9.data.response;
    // const stage6Stage = response6.data.stage;
    // setShowStage5_9(true);
    setStage5_9Output(response5_9.data.response);
    // setStage(response5_9.data.stage);
    
    const stage6Input = stage1Output + stage2Output + stage3Output + stage4Output + stage5Output + stage5_9Output;
    const response6 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_6/', stage6Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage6Output = response6.data.response;
    // const stage6Stage = response6.data.stage;
    // setShowStage6(true);
    setStage6Output(response6.data.response);
    // setStage(response6.data.stage);

    // console.log(typeof stage5Output)
    const stage7Input = stage1Output + claimExtractedText +  defendantExtractedText + claimantResponseExtractedText + defendantResponseExtractedText + stage5Output + stage6Output + stage5_9Output;
    const response7 = await axios.post('https://judgeaiv0.azurewebsites.net/uae_law_stage_7/', stage7Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // const stage7Output = response7.data.response;
    // setShowStage7(true);
    setStage7Output(response7.data.response);  
    setStage4SubmitLoading(false);
    closeAll()  
  };


  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box paddingTop={"40px"} paddingBottom={"40px"}>
        <VStack spacing={"40px"}>
        <Box>
        <Alert status='warning' maxW={"70vw"} borderRadius={"5px"}>
            <AlertIcon />
            Generated judgement is not legally binding or enforceable and is part of an experimental process conducted by JudgeAI Court.
        </Alert>
        </Box>
        {(userCredits === 0) && (!loadingScreen) && (<Box>
          {/* <Alert status='error'>
            <AlertIcon />
            Please purchase credits to use JudgeAI.
          </Alert> */}
          <Alert
          status='error'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
          borderRadius={"10px"}
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Out of Credits!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Please purchase credits to use JudgeAI.
          </AlertDescription>
        </Alert>
        </Box>)}
        {(userCredits > 0) && (<Box>
          <VStack spacing={"10px"}>
          <UploadButton onFilesChange={handleStage1FilesChange} isDisabled={(stage1SubmitDisabled || stage1SubmitLoading)} uploading={stage1FileUploading} />
          <Button colorScheme="teal" onClick={handleSubmitStage1} isDisabled={stage1SubmitDisabled} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          </VStack>
        </Box>)}
          {/* <Box>
          <VStack spacing={"10px"}>
          <UploadButton onFilesChange={handleStage1FilesChange} isDisabled={(stage1SubmitDisabled || stage1SubmitLoading)} />
          <Button colorScheme="teal" onClick={handleSubmitStage1} isDisabled={stage1SubmitDisabled} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          </VStack>
          </Box> */}
          {stage1Output && (
            <>
            <Box
              p={10}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from claim</Heading>
              <Markdown>{stage1Output}</Markdown>
            </Box>
            </>
          )}
          {showStage2 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <UploadButton onFilesChange={handleStage2FilesChange} 
                              message="Defendant is directed to upload response and evidence" 
                              isDisabled = {stage2SubmitDisabled || stage2SubmitLoading}
                              uploading={stage2FileUploading}
                              />
                <Button colorScheme="teal" onClick={handleSubmitStage2} isDisabled={stage2SubmitDisabled} isLoading={stage2SubmitLoading}>
                  Submit
                </Button>
              </VStack>
            </Box>
          )}
          {stage2Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from defendant's response</Heading>
              <Markdown>{stage2Output}</Markdown>
            </Box>
            </>
          )}
          {showStage3 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <UploadButton onFilesChange={handleStage3FilesChange} 
                              message="Claimant is directed to give response" 
                              isDisabled = {stage3SubmitDisabled || stage3SubmitLoading}
                              uploading={stage3FileUploading}
                              />
                <Button colorScheme="teal" onClick={handleSubmitStage3} isDisabled={stage3SubmitDisabled} isLoading={stage3SubmitLoading}>
                  Submit
                </Button>
                <Text>Or</Text>
                <Button colorScheme="red" onClick={handleSubmitStage3NoObjection} isDisabled={stage3SubmitDisabled} isLoading={stage3SubmitLoading}>
                  No objection
                </Button>
              </VStack>
            </Box>
          )}
          {stage3Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from claimant's clarification</Heading>
              <Markdown>{stage3Output}</Markdown>
            </Box>
            </>
          )}
          {showStage4 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <UploadButton onFilesChange={handleStage4FilesChange} isDisabled={stage4SubmitDisabled} message="Respondant is directed to give response" uploading={stage4FileUploading} />
                <Button colorScheme="teal" onClick={handleSubmitStage4} isDisabled={stage4SubmitDisabled} isLoading={stage4SubmitLoading}>
                  Submit
                </Button>
                <Text>Or</Text>
                <Button colorScheme="red" onClick={handleSubmitStage4} isDisabled={stage4SubmitDisabled} isLoading={stage4SubmitLoading}>
                  No objection
                </Button>
              </VStack>
            </Box>
          )}
          {stage4Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from defendant's clarification</Heading>
              <Markdown>{stage4Output}</Markdown>
            </Box>
            </>
          )}
          {stage4_9Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Evidence Analysis</Heading>
              <Markdown>{stage4_9Output}</Markdown>
            </Box>
            </>
          )}
          {stage4_9_5Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Evidence Conclusion</Heading>
              <Markdown>{stage4_9_5Output}</Markdown>
            </Box>
            </>
          )}
          {stage5Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Case Fable</Heading>
              <Markdown>{stage5Output}</Markdown>
            </Box>
            </>
          )}
          {stage5_9Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Relevant Legal Norms</Heading>
              <Markdown>{stage5_9Output}</Markdown>
            </Box>
            </>
          )}
          {stage6Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Ideal chronology</Heading>
              <Markdown>{stage6Output}</Markdown>
            </Box>
            </>
          )}
          {stage7Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Final uae_law</Heading>
              <Markdown>{stage7Output}</Markdown>
            </Box>
            </>
          )}
        </VStack>
        <div id="bottom">
        </div>
      </Box>
    </ChakraProvider>
  );
};

export default UaeLawJudgementPage;