import { useEffect } from "react"
import {
  ChakraProvider,
  Box,
  theme,
  Card,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react"
import Navbar from "../components/Navbar"
import Profile from "../components/Profile"
import Billing from "../components/Billing"
import ReactGA from 'react-ga4';

export const ProfilePage = () => {
  // useEffect for Google Analytics pageview tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/profile", title: "ProfilePage" });
  }, []); // Empty dependency array means this runs only on mount
  
  return (
  <ChakraProvider theme={theme}>
    <Navbar />
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="93vh"
      // border="2px solid red"
    >
      <Card 
        width='70vw' 
        maxW="600px"
        height="60vh"
      >
        <Tabs>
          <TabList>
            <Tab>Profile</Tab>
            <Tab>Billing</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Profile />
            </TabPanel>
            <TabPanel>
              <Billing />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>  
    </Box>
  </ChakraProvider>
)}
