import { useState, useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import UploadButton from "../components/UploadButton";
import axios from 'axios';
import Markdown from "react-markdown";
import {v4 as uuidv4} from 'uuid';
import { useUserAuth } from "../context/userAuthContext";
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ReactGA from 'react-ga4';

export const PerspectivePage = () => {
  const { user } = useUserAuth();

  const [stage1Files, setStage1Files] = useState<File[]>([]);
  const [stage1Output, setStage1Output] = useState<string>("");
  const [stage1SubmitLoading, setStage1SubmitLoading] = useState(false)
  const [stage1SubmitDisabled, setStage1SubmitDisabled] = useState(false)
  const [stage1FileUploading, setStage1FileUploading] = useState(false)

  const [stage2Output, setStage2Output] = useState<string>("");
  
  const [stage3Output, setStage3Output] = useState<string>("");

  const [userCredits, setUserCredits] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true);

  const toast = useToast()

  function addToast() {
    // toast({ description: 'some text' })
    toast({
      title: 'JudgeAI is analysing facts',
      description: "JudgeAI typically takes a minute to respond",
      status: 'loading',
      duration: null,
      // isClosable: true,
    })
  }

  function closeAll() {
    toast.closeAll()
  }

  // useEffect for Google Analytics pageview tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/perspective", title: "PerspectivePage" });
  }, []); // Empty dependency array means this runs only on mount

  useEffect(() => {
    const fetchUserData = async () => {
        if (user) { 
        const userEmail = user.email || '';
        setUserEmail(userEmail);
          
        try {
            const userDoc = await getDoc(doc(db, "users", userEmail));
            if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserCredits(userData.credits || 0);
            setLoadingScreen(false)
            } else {
            console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
        }
    };

    fetchUserData();
    }, [user]);

    const scrollToSection = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    
    useEffect(() => {
      if (stage1Output) {
        scrollToSection("bottom");
      }
    }, [stage1Output]);
    
    useEffect(() => {
      if (stage2Output) {
        scrollToSection("bottom");
      }
    }, [stage2Output]);
    
    useEffect(() => {
      if (stage3Output) {
        scrollToSection("bottom");
      }
    }, [stage3Output]);

  const uploadFilesToFirebase = async (files: File[], stage: number) => {
    const uploadedFiles: { fileName: string; url: string }[] = [];

    for (const file of files) {
      const fileRef = ref(storage, `perspective_${userEmail}_${stage}_${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      uploadedFiles.push({ fileName: file.name, url });
    }

    return uploadedFiles;
  };

  const handleStage1FilesChange = async (files: File[]) => {
    setStage1SubmitDisabled(true);
    setStage1SubmitLoading(true);
    setStage1FileUploading(true);
    
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 1);
      setStage1Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage1SubmitDisabled(false);
      setStage1SubmitLoading(false);
      setStage1FileUploading(false);
    }
  };
  

  const handleSubmitStage1 = async () => {
    addToast()
    setStage1SubmitLoading(true)
    setStage1SubmitDisabled(true)

    const userRef = doc(db, "users", userEmail);

    setUserCredits(userCredits - 1)

    await updateDoc(userRef, {
      credits: (userCredits - 1)
    });

    const interaction_id = uuidv4()
    console.log(interaction_id)

    if (stage1Files.length > 0) {
      const formData = new FormData();
      stage1Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });

      try {
        // const response = await axios.post('http://127.0.0.1:8000/judgement_stage_1/', formData, {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/perspective_stage_1/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setStage1Output(response.data.response);
        
        const stage2Input = response.data.response
        const response2 = await axios.post('https://judgeaiv0.azurewebsites.net/perspective_stage_2/', stage2Input, {
        headers: {
        'Content-Type': 'application/json',
        },
        });
        const stage2Output = response2.data.response;
        setStage2Output(stage2Output);

        const stage3Input = response.data.response + response2.data.response
        const response3 = await axios.post('https://judgeaiv0.azurewebsites.net/perspective_stage_3/', stage3Input, {
        headers: {
        'Content-Type': 'application/json',
        },
        });
        const stage3Output = response3.data.response;
        setStage3Output(stage3Output);

      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    setStage1SubmitLoading(false)
    closeAll()
  };


  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box paddingTop={"40px"} paddingBottom={"40px"}>
        <VStack spacing={"40px"}>
        {(userCredits === 0) && (!loadingScreen) && (<Box>
          {/* <Alert status='error'>
            <AlertIcon />
            Please purchase credits to use JudgeAI.
          </Alert> */}
          <Alert
          status='error'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
          borderRadius={"10px"}
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Out of Credits!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Please purchase credits to use JudgeAI.
          </AlertDescription>
        </Alert>
        </Box>)}
        {(userCredits > 0) && (<Box>
          <VStack spacing={"10px"}>
          <UploadButton onFilesChange={handleStage1FilesChange} isDisabled={(stage1SubmitDisabled || stage1SubmitLoading)} uploading={stage1FileUploading} />
          <Button colorScheme="teal" onClick={handleSubmitStage1} isDisabled={stage1SubmitDisabled} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          </VStack>
        </Box>)}
          {/* <Box>
          <VStack spacing={"10px"}>
          <UploadButton onFilesChange={handleStage1FilesChange} isDisabled={(stage1SubmitDisabled || stage1SubmitLoading)} />
          <Button colorScheme="teal" onClick={handleSubmitStage1} isDisabled={stage1SubmitDisabled} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          </VStack>
          </Box> */}
          {stage1Output && (
            <>
            <Box
              p={10}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from claim</Heading>
              <Markdown>{stage1Output}</Markdown>
            </Box>
            </>
          )}
          {stage2Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Analysis of facts</Heading>
              <Markdown>{stage2Output}</Markdown>
            </Box>
            </>
          )}
          {stage3Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Litigation perspective</Heading>
              <Markdown>{stage3Output}</Markdown>
            </Box>
            </>
          )}
        </VStack>
        <div id="bottom">
        </div>
      </Box>
    </ChakraProvider>
  );
};

export default PerspectivePage;