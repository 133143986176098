import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Checkbox,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// import { app } from '../firebase';
import { useNavigate } from 'react-router-dom';
// import { collection, addDoc } from "firebase/firestore"; 
import { collection, doc, setDoc } from "firebase/firestore"; 
import { db } from '../firebase';
import { useUserAuth } from '../context/userAuthContext';
import { FcGoogle } from "react-icons/fc";

// const auth = getAuth(app);

export default function SignupModal() {
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organization, setOrganization] = useState('');
  const [acceptedPolicies, setAcceptedPolicies] = useState(false);
  const navigate = useNavigate();
  const { signUp, googleSignIn } = useUserAuth();


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!acceptedPolicies) {
        alert("You must accept the disclaimer and privacy policy to sign up.");
        return;
      }

      // console.log({
      //   firstName,
      //   lastName,
      //   email,
      //   password,
      // });
  
      try {
      const userCredential = await signUp(email, password);
      const usersRef = collection(db, "users");
      await setDoc(doc(usersRef, email), {
        firstName: firstName,
        lastName: lastName,
        email: email,
        // password: password,
        organization: organization,
        isAdmin: false,
        credits: 50,
        coupon_redeemed: [],
      });

      // // User signed up successfully
      const user = userCredential.user;
      // navigate('/login')
      navigate("/");
      console.log('User signed up:', user);
      } catch (error) {
      console.error('Error signing up:', error);
      }
    };

    const handleGoogleSignIn = async () => {
      // e.preventDefault();
      if (!acceptedPolicies) {
        alert("You must accept the disclaimer and privacy policy to sign up.");
        return;
      }
      else {
        try {
          // await googleSignIn();
          const result = await googleSignIn();
          const user = result.user;
          console.log("uer is")
          console.log(user.email);
          const usersRef = collection(db, "users");
          if (user.email) {
            await setDoc(doc(usersRef, user.email), {
              firstName: user.displayName?.split(' ')[0] || '',
              lastName: user.displayName?.split(' ').slice(1).join(' ') || '',
              email: user.email,
              organization: "",
              isAdmin: false,
              credits: 50,
              coupon_redeemed: [],
            });
          }
          navigate("/");
        } catch (error) {
          console.log(error);
        }
      }
    };

  return (
    <Flex
      minH={'90vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <HStack>
              <Box>
                <FormControl id="firstName" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input 
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)} 
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input 
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                  />
                </FormControl>
              </Box>
            </HStack>
            <FormControl id="organization" isRequired>
              <FormLabel>Organization</FormLabel>
              <Input 
                  type="text"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)} 
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input 
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} 
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input 
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} 
                  />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <Checkbox
                isChecked={acceptedPolicies}
                onChange={(e) => setAcceptedPolicies(e.target.checked)}
              >
                I have read and accept the <Link color={'blue.400'} href="https://judgeai.space/disclaimer"  target="_blank" rel="noopener noreferrer">disclaimer</Link> and <Link color={'blue.400'} href="https://judgeai.space/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.
              </Checkbox>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                type='submit'
                >
                Sign up
              </Button>
            </Stack>
            <Button
                leftIcon={<FcGoogle />}
                loadingText="Submitting"
                size="lg"
                bg={'white'}
                color={'black'}
                borderWidth="1px"
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={handleGoogleSignIn}
                >
                Continue with Google
              </Button>
            <Divider pt={3}/>
            <Stack pt={3}>
              <Text align={'center'}>
                Already a user? <Link color={'blue.400'} href='/login'>Login</Link>
              </Text>
            </Stack>
          </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}
